.subscription-history {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    @include mq($until: mobile) {
        justify-content: center;
        width: 100%;
        margin: 0 auto;
    }

    &__overlay {
        position: absolute;
        top: 30px;
        right: 50px;
        min-width: 570px;
        max-width: 570px;
        width: 100%;
        min-height: 350px;
        padding: 20px;
        background-color: #fff;
        transition: opacity .3s;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        border-radius: 7px;
        box-shadow: 0 0 15px -10px rgba(0, 0, 0, .75);

        @include mq($until: mobile) {
            top: 40px;
        }

        @include mq($until: small) {
            min-width: unset;
            right: 0;
            width: 100%;
        }

        &_active {
            opacity: 1;
            z-index: 2;
            visibility: visible;
        }
    }

    &__overlay-head {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
        margin-bottom: 10px;

        @include mq($until: desktop) {
            align-items: start;
            flex-direction: column;
        }
    }

    &__overlay-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        top: 30px;
        right: 0;
        background: $secondary-font-color;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        transform: rotate(-90deg);
        transition: transform .3s;
        color: #fff;

        @include mq($until: small) {
            top: 10px;
        }

        &_active {
            transform: rotate(90deg);
        }
    }


    &__datepicker-input {
        background-color: $aux-bg-color;
    }

    &__mode-buttons {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
    }

    &__mode-button {
        background-color: transparent;
        color: $secondary-font-color;
        border: 1px solid $secondary-font-color;
        height: unset;
        padding: 5px 5px;

        &:hover {
            color: #fff;
        }

        &_active {
            color: #fff;
            background-color: $secondary-font-color;
        }
    }
}
