.subscription-plan {
    width: 100%;
    border-radius: 7px;
    background-color: #fff;
    padding: 40px;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, .75);
    text-align: left;

    &__inner-wrapper {
        max-width: 320px;
        
        @include mq($until: mobile) {
            max-width: 100%;
        }
    }

    &__name {
        display: flex;
        justify-content: space-between;
        font-weight: 800;

        & > p {
            margin-bottom: 5px;
        }
    }

    &__price {
        display: block;
        font-size: 14px;
        font-weight: 600;
    }

    &__create-date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        & > p {
            font-weight: 800;
            margin-bottom: 0;
        }
    }

    &__credits {
        color: $secondary-font-color;
        font-weight: 700;
    }

    &__status {
        font-weight: 700;
        margin-bottom: 0;
    }

    &__expire-date {
        font-size: 12px;
        color: $minor-font-color;
        font-weight: 600;
    }

    &__button-wrapper {
        text-align: left;

        @include mq($until: mobile) {
            text-align: center;
        }
    }

    &__button {
        margin-top: 60px;
    }
}
