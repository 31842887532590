.talent-rank-badge {
    @include friendlySectionAppear;

    &--small {
        font-size: 26px;
    }

    &--large {
        font-size: 100px;
    }

    &--default {
        font-size: 60px;
    }
}
