.view-my-job-post-applicants-modal {
    border-radius: 7px;
    max-width: 790px;

    @include mq($until: mobile) {
        max-width: unset;
        border-radius: 0;
    }

    &__header {
        align-items: center;
    }

    &__sub-header {
        @include friendlySectionAppear(.1s);
        color: $secondary-font-color;
        text-align: center;

        span {
            font-weight: 800;
        }
    }

    &__body {
        padding-bottom: 20px;
    }

    &__footer {
        button {
            width: 184px;
        }
    }

    &__sort-bar {
        flex: 1;

        .custom-select {
            margin-top: 0;
        }
    }

    @include mq($until: mobile) {
        .modal__content {
            grid-template-rows: repeat(3, auto);
        }
    }
}
