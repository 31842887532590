.contacts-step {
    &__sub-title {
        margin: 32px 0 0;
        font-size: 18px;
        line-height: 26px;

        + .wizard-step__form-row {
            margin-top: 20px;
            justify-content: flex-start;

            .wizard-step__form-cell {
                width: auto;
            }
        }
    }

    .wizard-step__form-cell {
        max-width: 292px;

        @include mq($until: tiny) {
            max-width: unset;
        }
    }
}
