.filters-sidebar {
    &__inner {
        max-height: initial;

        @include mq($until: desktop) {
            background-color: transparent;
        }
    }

    &__head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 0 30px;
        min-height: 110px;
    }

    &__title {
        @include friendlySectionAppear(.2s);

        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        min-height: 32px;
        margin: 0;
    }

    &__heading {
        margin: 26px 30px 0;

        &:only-child {
            margin-bottom: 30px;
        }
    }

    &__filter-section-header {
        margin: 35px 16px 17px 30px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        @include mq($until: desktop) {
            margin-bottom: 0;
        }
    }

    &__filter-heading {
        font-size: 16px;
        font-weight: 800;
        color: $primary-font-color;
    }

    &__footer {
        margin: 32px 30px 26px;
    }

    .expansion-panel__content {
        margin: 0 30px 20px;
    }

    &__filter-match {
        display: flex;

        .floating-label {
            margin: 0 22px;
            flex-basis: 66px;

            input {
                text-align: center;
            }
        }
    }

    &__filter-checkbox-item {
        margin-bottom: 20px;

        .custom-checkbox__label {
            text-transform: capitalize;
        }
    }

    &__actions {
        margin-top: 75px;
        display: flex;
        justify-content: center;
        padding: 0 30px;

        svg {
            transform: translateY(1px);
            font-size: 12px;
            margin-right: 18px;
            z-index: -1;
        }
    }
}
