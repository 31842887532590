.list {
    &--disable-marks {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &--disable-spacing {
        padding: 0;
        margin: 0;
        list-style-position: inside;
    }
}
