.resend-btn {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &__label {
        grid-column: 2 / 3;
    }

    &__timer {
        grid-column: 3 / 4;
        padding-left: 7px;
        padding-right: 7px;
    }
}
