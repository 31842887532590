.enterprise-job-post-page {
    &__empty-jobs,
    &__empty-applicants {
        @include friendlySectionAppear(.1s);
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 5px;

        span {
            cursor: pointer;
            color: $secondary-font-color;
            text-decoration: underline;
        }

        svg {
            font-size: 20px;
            fill: $secondary-font-color;
        }
    }

    .plus-btn {
        opacity: 0;
        animation:
            reveal-from-bottom-with-scale .6s cubic-bezier(.22, 1, .36, 1) .3s forwards,
            fade-in .3s linear forwards .3s;
    }

    &__add-btn {
        position: fixed;
        right: 57px;
        bottom: 39px;

        @include mq($until: mobile) {
            bottom: 99px;
        }
    }

    .talent-cards-result__actions {
        align-items: baseline;

        @include mq($until: tiny) {
            gap: 20px;
        }
    }
}
