@mixin ellipsis($width: 100%) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin stretch() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin absoluteCenter() {
    @include stretch;
    margin: auto;
}

@mixin debug($withColor: null, $lineColor: #000) {
    outline: 1px solid $lineColor !important;/* stylelint-disable-line */

    @if $withColor {
        background-color: rgba(0,255,0,.1) !important;/* stylelint-disable-line */
    }
}

@mixin imgCover($width: 100%, $height: 100%) {
    width: $width;
    height: $height;
    object-fit: cover;
}

@mixin onTouchDevices() {
    @media (-moz-touch-enabled: 1), (pointer: coarse) {
        @content;
    }
}

@mixin onMouseDevices() {
    @media (hover) {
        @content;
    }
}

@mixin ratio-box($ratio: 1 / 1) {
    position: relative;

    > * {
        left: 0;
        top: 0;
        position: absolute;
    }

    &:before {
        content: "";
        display: block;
        height: 0;
        padding-top: (1 / $ratio) * 100%;
    }
}

@mixin undo-ratio-box() {
    position: initial;

    > * {
        left: initial;
        position: initial;
        top: initial;
    }

    &:before {
        content: initial;
        display: initial;
        height: initial;
        padding-top: initial;
    }
}

@mixin customScrollbars($thumb: $secondary-font-color, $track: #ccc) {
    scrollbar-width: thin;
    scrollbar-color: lighten($thumb, 15%) $track;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: $track;
    }

    &::-webkit-scrollbar-thumb {
        background-color: lighten($thumb, 15%);
        border-radius: 10px;

        &:hover {
            background-color: $thumb;
        }

        &:active {
            background-color: $thumb;
        }
    }

    &::-webkit-scrollbar-thumb:vertical {
        min-height: 1.5rem;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        min-height: 1.5rem;
    }
}

@mixin hideFloatingLabelAnimation() {
    label {
        opacity: 0;
        animation: fade-in $main-transition forwards .35s;
    }
}

@mixin landingShadow($opacity: .4) {
    opacity: $opacity;
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    width: 100%;
    z-index: -1;

    &:before,
    &:after {
        pointer-events: none;
        content: "";
        position: absolute;
    }

    &:before {
        left: 0;
        top: 0;
        background-image: radial-gradient(47.42% 50% at 50% 50%, rgba(114, 128, 255, .42) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
        right: 0;
        bottom: 0;
        background-image: radial-gradient(47.42% 50% at 50% 50%, rgba(255, 114, 224, .21) 0%, rgba(255, 255, 255, 0) 100%);
    }
}

@mixin friendlySectionAppear($delay: 0s, $cubic-bezier-fn: $main-cubic-bezier-fn, $duration: .3s) {
    opacity: 0;
    animation: fade-in $duration $cubic-bezier-fn forwards $delay;
}

@mixin homeRevealAnimation($duration: .3, $delay: 0) {
    animation:
        reveal-from-bottom-with-scale #{$duration}s $main-cubic-bezier-fn forwards #{$delay}s,
        fade-in #{$duration * 1.1}s linear forwards #{$delay}s;
}
