.tip {
    width: $tip-size;
    height: $tip-size;
    flex-shrink: 0;
    border-radius: $tip-size;
    background-color: $tip-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 14px;
    color: $secondary-font-color;

    &:hover {
        cursor: pointer;
    }
}
