.talent-board {
    &__list-title {
        @include friendlySectionAppear(.2s);

        font-weight: 900;
        font-size: 24px;
        line-height: 32px;

        @include mq($until: desktop) {
            margin-top: 30px;
        }
    }

    .sidebar__inner {
        max-height: initial;

        @include mq($until: desktop) {
            background-color: transparent;
        }
    }

    .sidebar__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        @include mq($until: desktop) {
            margin: 30px 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            min-height: initial;
        }

        .sidebar__subtitle {
            font-size: 16px;
            font-weight: 800;
            color: $primary-font-color;
            margin-bottom: 0;
        }
    }


    &__sidebar {
        .sidebar-nav__item-label span {
            cursor: pointer;
        }

        .sidebar__title {
            @include friendlySectionAppear(.2s);
        }

        &__actions {
            margin-top: 75px;
            display: flex;
            justify-content: center;
            padding: 0 30px;

            svg {
                transform: translateY(1px);
                font-size: 12px;
                margin-right: 18px;
                z-index: -1;
            }
        }

        &__mobile-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            grid-row-gap: 15px;

            button {
                margin: 0;
                padding: 0;
            }

            &__selected {
                margin-bottom: 0;

                span {
                    @include friendlySectionAppear(.1s);

                    font-weight: 700;
                    color: $secondary-font-color;
                }
            }

            &__actions {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__edit {
            color: $secondary-font-color;
        }
    }

    &__empty-state {
        @include friendlySectionAppear(.1s);

        span {
            font-weight: 700;
            color: $secondary-font-color;
        }
    }
}
