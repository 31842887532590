.talent-details {
    &__contacts {
        @include mq($until: mobile) {
            width: calc(100% + 2 * 19px);
            margin-left: -19px;
        }

        .expansion-panel__content {
            display: flex;
            align-items: center;

            @include mq($until: mobile) {
                flex-direction: column;
                align-items: normal;
            }
        }
    }

    &__contacts-entry {
        display: flex;
        align-items: center;
        color: $secondary-font-color;

        @include after-first(1) {
            margin-left: 77px;

            @include mq($until: mobile) {
                margin-left: 0;
                margin-top: 30px;
            }
        }

        .hidden-text {
            user-select: none;
            color: transparent;
            text-shadow: 0 0 5px rgba($secondary-font-color, .5);
        }

        svg {
            font-size: 24px;
            flex-shrink: 0;
            margin-right: 16px;
        }
    }
}
