#root { /* stylelint-disable-line */
    min-height: 100%;
    display: grid;
    grid-template-columns:
        [col-start] auto
        [col-end];
    grid-template-rows:
        [row-start header-area-start] var(--header-height)
        [content-area-start header-area-end] auto
        [content-area-end footer-area-start] minmax($footer-height, min-content)
        [row-end footer-area-end];
}

.page-content {
    grid-column: col;
    grid-row: content-area;
    min-width: 0;

    @at-root .site-header.is-hidden ~ & {
        grid-row-start: 1;
    }

    @at-root .site-footer.is-hidden ~ & {
        grid-row-end: -1;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: auto minmax(0, $container-width) auto;

    > * {
        grid-column: 2 / 3;
    }

    &_wide {
        > * {
            grid-column: 1 / 3;
        }
    }
}
