.payment-method-modal {
    border-radius: 10px;

    &__body {
        padding-bottom: 30px;
    }

    &__submit-button {
        margin-top: 30px;
    }
}
