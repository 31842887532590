.talent-details {
    &__similar {
        @include friendlySectionAppear;

        .sidebar__head {
            padding: 30px;

            > div {
                font-weight: 300;
                margin-bottom: 5px;
            }
        }

        .sidebar__title {
            @include friendlySectionAppear(.1s);
        }
    }

    &__similar-list {
        min-height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__similar-item {
        @include friendlySectionAppear(.3s);
        min-height: 120px;
        display: grid;
        align-items: center;
        grid-template-columns: $enterprise-talent-similar-avatar-size 1fr 60px;
        grid-column-gap: 15px;
        padding: 0 30px;
        width: 100%;

        @include after-first(1) {
            border-top: 1px solid $sidebar-nav-item-bg;
        }
    }

    &__similar-item-image {
        width: $enterprise-talent-similar-avatar-size;
        height: $enterprise-talent-similar-avatar-size;
        position: relative;
        flex-shrink: 0;

        .user-avatar__image {
            font-size: $enterprise-talent-similar-avatar-size;
        }
    }

    &__similar-item-fullname {
        color: $secondary-font-color;
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        overflow-wrap: break-word;
        min-width: 0;
    }

    &__similar-item-match-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 700;

        span {
            font-size: 27px;
            line-height: 27px;
            color: $success-color;
        }
    }

    &__similar-not-found {
        height: 100%;
        width: 100%;
        padding: 10px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
