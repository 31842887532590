.subscription-plan-page {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 30px;

    &__title {
        margin: 0;
    }

    &__plans-list {
        @include mq($until: desktop) {
            flex-direction: column;
        }

        &__card {
            @include mq($until: desktop) {
                max-width: unset;
            }
        }
    }

    &__questions-section {
        max-width: unset;
    }
}
