.input-group {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    label {
        @include ellipsis;
        color: $secondary-font-color;
        flex-basis: 500px;
    }

    &.is-required {
        label {
            &:not(:empty):after {
                content: "*";
                display: inline;
            }
        }
    }

    &--month-year {
        > * {
            @include after-first(1) {
                flex-shrink: 0;
                margin-left: 24px;
                flex-basis: 60px;
            }
        }

        .custom-select__single-value,
        .custom-select__placeholder {
            text-align: center;
            width: 100%;
        }
    }
}
