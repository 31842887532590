.job-post-form {
    @include friendlySectionAppear;
    height: 100%;
    padding: 0 30px;

    @include mq($until: mobile) {
        margin-bottom: $mobile-user-area-height;
        padding: 0;
    }

    &.is-hidden {
        display: none;
    }

    &__content {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: $wizard-sidebar-width 1fr;
        grid-auto-rows: max-content;
        padding: 40px 0 35px;

        @include mq($until: tablet) {
            grid-template-columns: auto;
            grid-template-rows: reapeat(3, auto);
            grid-row-gap: 16px;
        }

        @include mq($until: mobile) {
            padding: 0;
            background-color: $secondary-bg-color;
            grid-row-gap: 0;
        }
    }

    &__inner {
        width: 100%;
        z-index: 1;
    }

    &__inner-actions {
        width: 100%;
        grid-column: 2/3;

        @include mq($until: tablet) {
            grid-column: auto;
        }
    }

    &__main {
        height: 100%;
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 30px 60px;
        min-height: 410px;

        @include mq($until: mobile) {
            padding-bottom: 30px;
        }
    }

    &__qualifiers-values-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    &__qualifiers-values {
        display: flex;
        border-radius: 30px;
        background-color: #ecf2f8;
        color: #1688f3;
        margin: 0;
    }

    &__qualifiers-tag {
        border-radius: 30px;
        font-size: 12px;
        padding: 4px 12px;
        color: #1688f3;
        background-color: #ecf2f8;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
