.register-auth-page {
    height: 100%;
    display: grid;
    grid-template-columns: auto minmax(0, 987px) auto;
    align-content: center;
    padding-right: 83px;

    @include mq($until: desktop) {
        padding: 0 40px;
    }

    @include mq($until: tablet) {
        align-content: start;
        padding: 0 20px 50px;
    }

    &__content {
        grid-column: 2 / 3;
        display: flex;
        justify-content: space-between;
        height: 100%;

        @include mq($until: tablet) {
            grid-column: 1 / -1;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }
    }

    &__left-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @include mq($until: tablet) {
            width: 100%;
        }
    }

    &__right-col {
        align-self: flex-start;

        @include mq($until: tablet) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
        }

        h1 {
            font-size: 26px;
            font-weight: 900;
            line-height: 33px;
            text-align: center;
            text-transform: uppercase;

            @include mq($until: tablet) {
                margin-top: 40px;
                margin-bottom: 0;
            }

            @include mq($until: mobile) {
                font-size: 17px;
                margin-top: 0;
                font-weight: 800;
                line-height: 21px;
            }
        }
    }

    &__login-link {
        line-height: 24px;
        margin-top: 23px;
        text-align: center;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .5s;
        will-change: opacity;

        a {
            color: $secondary-font-color;
        }
    }

    &__illustration {
        @include friendlySectionAppear(.1s, linear);

        @include mq($until: tablet) {
            display: none;
        }
    }

    &__logo {
        @include friendlySectionAppear(.1s, linear);
        display: flex;
        font-size: 118px;

        @include mq($until: desktop) {
            font-size: 90px;
        }

        @include mq($until: tablet) {
            font-size: 60px;
            margin-top: 40px;
        }

        @include mq($until: mobile) {
            margin-top: 21px;
            align-self: flex-start;
            font-size: 28px;
        }
    }

    &__terms-row {
        margin-top: 25px;
        align-self: flex-start;
    }

    &__terms-link {
        color: $secondary-font-color;
        line-height: 20px;
        cursor: pointer;
        font-weight: 800;
    }

    form {
        width: 352px;
        border-radius: 7px;
        background-color: $secondary-bg-color;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 28px;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .1s;
        will-change: opacity;

        @include mq($until: tablet) {
            width: 400px;
        }

        @include mq($until: mobile) {
            width: 100%;
            max-width: 352px;
        }

        .floating-label {
            @include first-child {
                margin-top: 0;
            }
        }

        button {
            width: 184px;
            margin-top: auto;
        }
    }

    &__social-signup {
        margin-top: 16px;
        width: 352px;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .1s;

        @include mq($until: tablet) {
            width: 400px;
        }

        @include mq($until: mobile) {
            width: 100%;
            max-width: 352px;
        }

        & + & {
            animation-delay: .17s;
        }
    }

    &__separator {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $minor-font-color;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .24s;
        position: relative;
        max-width: 292px;
        margin: 20px auto;
        width: 100%;

        + form {
            margin-top: 0;
            animation-delay: .31s;
        }

        &:before,
        &:after {
            border-top: 1px solid $minor-font-color;
            content: "";
            display: block;
            position: absolute;
            top: .5em;
            width: calc(50% - 1.2em);
        }

        &:before {
            right: 0;
        }

        &:after {
            left: 0;
        }
    }

    .register-successful-screen {
        opacity: 0;
        animation: fade-in $main-transition forwards;

        .result-screen__content {
            line-height: 24px;
        }

        .result-screen__body {
            animation-delay: .4s;
        }

        .result-screen__head,
        .result-screen__footer {
            svg {
                animation-delay: .3s;
            }
        }
    }

    &--talent {
        .register-auth-page__illustration {
            font-size: 430px;

            @include mq($until: desktop) {
                font-size: 325px;
            }
        }

        form {
            min-height: 418px;
        }
    }

    .enterprise-account-type-option {
        display: flex;
        flex-direction: column;

        > span {
            font-size: 14px;
            line-height: 20px;

            @include first-child {
                margin-bottom: 1px;
                font-weight: 700;
                color: $secondary-font-color;
            }
        }
    }

    &--enterprise {
        .register-auth-page__illustration {
            font-size: 506px;

            @include mq($until: desktop) {
                font-size: 430px;
            }
        }

        form {
            min-height: 531px;
            padding-bottom: 60px;
        }

        h1 {
            margin-top: 0;
        }

        .custom-select {
            &__option {
                padding: 13px 20px 12px;

                &--is-selected {
                    span {
                        @include first-child {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
