.talent-details {
    &__proficiency-portfolio {
        padding: 30px 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: left;

        &__title {
            margin-top: 0;
            margin-bottom: 40px;
            font-weight: 800;
        }

        &__qualities {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-row-gap: 30px;
            grid-column-gap: 120px;

            @include mq($until: mobile) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr;
            }

            &__item-title {
                margin-top: 0;
                margin-bottom: 10px;
                font-weight: 800;
            }

            &__item-type {
                margin-bottom: 0;
            }
        }
    }
}
