.wizard-sidebar {
    &.without-steps {
        .sidebar__inner {
            height: auto;
        }

        .sidebar__head {
            height: 110px;
            flex-basis: auto;

            @include mq($until: tablet) {
                display: none;
            }
        }
    }

    .sidebar__inner {
        max-height: calc(100vh - var(--header-height) + 140px);
        height: 530px;
    }

    &__content {
        padding: 0 30px 32px;
        opacity: 0;
        animation: fade-in $main-transition forwards;
    }
}
