.payment-method {
    width: 55%;
    margin-top: 30px;

    &__head {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        & > p {
            margin-bottom: 0;
        }
    }

    &__cards-wrapper {
        @include customScrollbars;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__card {
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid transparent;
        transition: all $main-transition;
        text-align: left;

        &_selected {
            border: 1px solid $secondary-font-color;
            box-shadow: 0 3px 6px rgba(#000, .16);
        }

        &:hover {
            border: 1px solid $secondary-font-color;
            box-shadow: 0 3px 6px rgba(#000, .16);
        }


        &__main-info {
            padding: 15px;
            display: flex;
            align-items: flex-start;
            color: #6a6a6a;
            font-weight: 600;
            border-bottom: 2px solid #f8f8f8;

            svg {
                width: 25px;
                height: 25px;
            }

            & > p {
                margin-bottom: 0;
                margin-left: 5px;
            }
        }

        &__address-info {
            padding: 15px;

            & > p {
                margin: 0;
                color: #6a6a6a;
                font-weight: 600;
            }
        }

        &__buttons-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 5px 10px;
        }

        &__delete-button {
            background-color: transparent;
            color: $unsuccess-color;
            box-shadow: none;

            &:hover,
            &:active {
                background-color: transparent;
                color: $unsuccess-color;
            }
        }
    }

    &__button-wrapper {
        margin-top: 10px;
    }

    @include mq($until: tablet) {
        width: 100%;
    }
}
