.custom-control-with-logo {
    display: flex;
    align-items: center;
    grid-gap: 5px;

    $logo-size: 25px;

    &__image-wrapper {
        max-width: $logo-size;
        margin-bottom: 4px;
        line-height: 0;


        img {
            width: 100%;
        }
    }
}
