$talent-trends-board-row-gap: 12px;
$talent-trends-board-col-gap: $talent-trends-board-row-gap * 2;

.talent-trends {
    @include friendlySectionAppear;
    text-align: center;
    margin: 25px 0;

    @include mq($from: mobile, $until: tablet) {
        text-align: start;
        margin: 0;
    }

    &__heading {
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 20px;

        @include mq($until: tablet) {
            display: none;
        }
    }

    &__suggestion {
        font-weight: 800;

        a {
            color: $secondary-font-color;
        }
    }

    &__board {
        border: none;
        border-collapse: collapse;
        margin: 0 #{-$talent-trends-board-col-gap} 28px;
        padding: 0;
        table-layout: auto;

        tr {
            border: none;
            margin: 0;
        }

        th,
        td {
            padding: $talent-trends-board-row-gap $talent-trends-board-col-gap;
            text-align: left;
        }

        th {
            font-size: 18px;
            font-weight: 800;
            white-space: nowrap;
        }

        @include mq($until: mobile) {
            margin: 0;
            width: 100%;

            thead {
                clip-path: inset(50% 50%);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            tr {
                display: block;
                margin-bottom: $talent-trends-board-col-gap;
            }

            td {
                border-bottom: 1px solid $form-border-color;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            td:before {
                /**
             * aria-label has no advantage, it won't be read inside a table
             * content: attr(aria-label);
             */
                content: attr(data-label);
                font-weight: 800;
            }

            td:last-child {
                border-bottom: none;
            }
        }
    }
}
