.company-profiles-modal {
    &__label {
        display: inline-block;
        color: $secondary-font-color;
    }

    &__field-title {
        margin-bottom: 10px;
    }

    &__form {
        display: grid;
        grid-template-rows: min-content min-content 1fr;
        grid-template-columns: 1fr;
    }

    .merge-company-body {
        display: grid;
        height: 400px;

        &__actions {
            align-items: flex-end;
        }
    }
}
