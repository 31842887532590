.wizard-items-list {
    &__item {
        opacity: 0;
        animation: fade-in $main-transition forwards;
        padding-bottom: 25px;

        @include after-first(1) {
            border-top: 1px solid $wizard-sidebar-active-step-bg;
            padding-top: 25px;
        }
    }

    &__item-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
    }

    &__item-title {
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 700;
        color: $secondary-font-color;
        word-break: break-word;

        span {
            font-weight: 400;
            color: $primary-font-color;
        }
    }

    &__item-actions {
        white-space: nowrap;
    }

    &__item-content {
        font-size: 16px;
        line-height: 32px;

        p {
            margin: 0;
            word-break: break-word;
        }

        &--condensed {
            line-height: 1.2;
            margin: 8px 0;
            white-space: pre-line;
        }

        &--highlighted {
            font-weight: 700;
        }
    }

    &__experience-item {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: max-content 1fr;
        grid-column-gap: 15px;

        @include mq($until: mobile) {
            grid-template-rows: max-content 1fr;
            grid-template-columns: 1fr;
        }

        &:nth-child(n+2) {
            border-top: 1px solid $sidebar-nav-item-bg;
        }

        &__logo-wrapper {
            $size: 50px;

            margin-top: 30px;
            max-width: $size;
            min-width: $size;

            img {
                width: 100%;
            }
        }

        &__experience:nth-child(n+2) {
            border-top: 0;
        }
    }
}
