.site-header {
    display: grid;
    grid-row: header-area;
    grid-template-columns: auto 1fr auto;
    padding: 0 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--header-height);
    z-index: 20;
    opacity: 1;

    @include mq($until: small) {
        padding: 0 20px;
    }

    @include mq($until: tiny) {
        padding: 0 15px;
    }

    &:after {
        background-color: $secondary-bg-color;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .05);
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity .2s linear;
    }

    &.is-processing,
    &.is-flat {
        .site-header__link {
            &:hover,
            &.is-active {
                color: #1272cc;
            }

            @include mq($until: tiny) {
                @include after-first(1) {
                    margin-left: 8px;
                }
            }
        }

        &:after {
            @include mq($from: mobile) {
                opacity: 0;
            }
        }
    }

    &.is-hidden {
        display: none;
    }

    &.with-mobile-menu {
        &:after {
            transition-duration: .1s;
            opacity: 1;
        }

        .site-header__middle {
            > * {
                display: none;
            }
        }

        .site-header__nav {
            display: none;
        }
    }

    &__start,
    &__middle,
    &__end {
        display: flex;
        align-items: center;

        @include mq($from: mobile) {
            @include friendlySectionAppear(.1s, linear);
        }
    }

    &__middle {
        justify-content: center;
    }

    &__logo {
        display: flex;
        font-size: 40px;
        transition: all $main-transition;

        @include mq($from: mobile) {
            @include friendlySectionAppear(.1s, linear);
        }

        @include mq($until: small) {
            font-size: 28px;
        }
    }

    &__nav {
        margin-left: 50px;

        @include mq($from: mobile) {
            @include friendlySectionAppear(.1s);
        }

        @include mq($until: tiny) {
            margin-left: 15px;
        }

        a {
            @include after-first(1) {
                margin-left: 50px;

                @include mq($until: small) {
                    margin-left: 40px;
                }

                @include mq($until: tiny) {
                    margin-left: 8px;
                }
            }

            @include last(1) {
                @include mq($until: tiny) {
                    margin-right: 5px;
                }
            }
        }
    }

    &__link {
        line-height: 24px;
        font-size: 13px;
        font-weight: 600;
        flex-shrink: 0;
        transition: all .15s linear;

        &:hover,
        &.is-active {
            color: $secondary-font-color;
        }
    }
}

.site-header-intersection-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 1000;
}
