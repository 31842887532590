.career-fields {
    .wizard-step {
        &__title {
            margin-bottom: 0;
        }

        &__subtitle {
            margin-bottom: 52px;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 35px;
        grid-column-gap: 15px;
        justify-content: space-between;

        @include mq($until: desktop){
            grid-template-columns: 1fr;
        }
    }
}
