button {
    color: inherit;
    font-family: $main-font;
    font-size: $base-font-size;
    font-weight: 400;
    border: none;
    touch-action: manipulation;
    background-image: none;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    outline: none;

    &:hover,
    &:active {
        cursor: pointer;
        outline: none;
    }
}

.btn {
    color: $button-primary-color;
    font-family: $main-font;
    font-size: $base-font-size;
    font-weight: 400;
    padding: 0 14px;
    height: 40px;
    border: none;
    transition: all $main-transition;
    display: inline-flex;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: $button-primary-bg;
    box-shadow: 0 0 20px rgba(0, 0, 0, .16);
    outline: none;
    vertical-align: middle;
    text-transform: uppercase;
    width: auto;

    &:hover,
    &:active {
        background-color: $button-primary-bg-a;
        cursor: pointer;
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        background-color: $button-disabled-bg;
    }

    &--success {
        background-color: $button-success-bg;

        &:hover,
        &:active {
            background-color: $button-success-bg-a;
        }
    }

    &--warning {
        color: $unsuccess-color;
        background-color: #f8e4ef;

        &:hover {
            color: $unsuccess-color;
            background-color: #f8e4ef;
        }
    }

    &--text,
    &--secondary {
        background-color: transparent;
        color: $secondary-font-color;
        border: 1px solid $button-primary-bg;
        box-shadow: none;

        &:hover,
        &:active {
            color: $button-text-disabled-color;
        }

        &:disabled {
            color: $button-text-disabled-color;
            border: none;
            background-color: transparent;
        }
    }

    &--text {
        border: none;

        &:hover,
        &:active {
            color: $secondary-font-color;
            background-color: transparent;
        }
    }
}

.action-button {
    display: inline-flex;
    background-color: transparent;
    transition: all $main-transition;
    font-size: 20px;
    line-height: 15px;
    padding: 8px;
    border-radius: 7px;
    color: $secondary-font-color;

    @include after-first(1) {
        margin-left: 10px;
    }

    &:hover {
        background-color: #d7e7f7;
        cursor: pointer;
    }

    &--warning {
        &:hover {
            color: $unsuccess-color;
            background-color: #f8e4ef;
        }
    }
}
