.talent-info-modal {
    border-radius: 7px;

    &__body {
        &__content-wrapper {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            &__progress-bar {
                flex-basis: 130px;
            }

            @include mq($until: mobile) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, max-content);
            }
        }
    }

    @include mq($until: mobile) {
        padding: 0;
        border-radius: 0;

        .modal__close {
            z-index: 1;
            top: 38px;
        }

        .modal__content {
            align-items: stretch;
        }

        .modal__body {
            max-height: 100vh;
        }
    }

    &--wide {
        max-width: 904px;
    }

    $experience-wizard-item-padding: 15px;

    .wizard-items-list {
        &__item {
            @include after-first(1) {
                border-top: none;
                padding-top: $experience-wizard-item-padding;
            }
        }
    }

    &__section {
        padding-bottom: 46px;

        &--condensed {
            .wizard-items-list {
                &__item {
                    padding-top: 0;
                    padding-bottom: 10px;
                }

                &__item-title {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__section-heading {
        line-height: 30px;
        color: $primary-font-color;
        font-size: 24px;
        font-weight: 800;
        margin: 0 0 34px;
    }

    &__info--highlighted {
        font-weight: 700;
        flex-shrink: 0;

        @include mq($until: tiny) {
            max-width: 100%;
        }
    }

    &__item-tag {
        color: $success-color;
        font-weight: 700;
    }

    &__item-row {
        display: flex;
        justify-content: space-between;

        &--shifted {
            margin-top: 8px;
        }

        &--condensed {
            justify-content: flex-start;

            p {
                line-height: 1.2;
            }

            > * {
                @include after-first(1) {
                    margin-left: 20px;
                }
            }
        }

        > .talent-info-modal__item-tag {
            flex-grow: 0;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }

    &__label-list {
        align-items: center;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        margin-right: -10px;
    }

    &__label-tag {
        border-radius: 30px;
        background-color: $form-value-tag-bg;
        color: $secondary-font-color;
        margin: 0 10px 18px 0;
        font-size: 12px;
        padding: 4px 12px;
    }

    .assessment-results {
        margin-bottom: 50px;

        @include mq($until: mobile) {
            margin-bottom: 10px;
        }
    }
}
