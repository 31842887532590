.privacy-step {
    .wizard-step {
        &__form-cell {
            flex-direction: column;
        }
    }

    .custom-radio {
        &__label {
            margin-left: 15px;
        }
    }

    &__nested-row {
        display: flex;
        padding-left: 37px;
        max-height: 0;
        overflow: hidden;
        transition: all $main-transition;

        &.is-expanded {
            max-height: 100px;

            @include mq($until: desktop) {
                max-height: unset;
            }
        }

        @include mq($until: desktop) {
            flex-direction: column;
        }

        .custom-radio {
            flex-shrink: 0;
            padding-top: 22px;

            @include mq($from: desktop) {
                @include after-first(1) {
                    margin-left: 25px;
                }
            }

            @include last-child {
                flex-shrink: 1;
            }

            &__label {
                margin-left: 5px;
            }
        }
    }

    &__option {
        display: flex;
        align-items: center;

        &.is-faded {
            .custom-radio {
                &__label {
                    color: #ccc;
                }
            }
        }

        .tip {
            margin-left: 50px;
        }
    }
}
