.pool-page {
    $hh-pool-sections-gap: 18px;
    $hh-pool-sections-padding-inline: 30px;
    min-height: 100%;
    display: grid;
    grid-template-columns: 352px 1fr;
    grid-column-gap: $hh-pool-sections-gap;
    width: 100%;
    max-width: calc(352px + #{$hh-pool-sections-gap} + 720px + 2 * #{$hh-pool-sections-padding-inline});
    margin: 0 auto;
    padding: 40px $hh-pool-sections-padding-inline;

    @include mq($until: desktop) {
        display: block;
        padding-top: 30px;
        margin-bottom: $mobile-user-area-height;
    }

    @include mq($until: mobile) {
        padding: 0 19px 40px;
    }

    &--tb,
    &--hh,
    &--jp {
        .talent-summary-card {
            background-color: #fff;
        }
    }

    &--jp {
        .talent-cards-result__action-item {
            margin-top: 0;
        }
    }

    .custom-select {
        margin-top: -2px;
    }

    .custom-select__label {
        top: 2px;
    }
}
