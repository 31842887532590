.terms-modal {
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .16);
    max-width: 718px;

    @include mq($until: mobile) {
        max-width: unset;
    }

    + .modal__backdrop {
        background-color: rgba(#f8f8f8, .6);
    }

    .modal__content {
        max-height: 650px;
        grid-template-rows: auto 1fr auto;

        @include mq($until: mobile) {
            max-height: unset;
        }
    }

    .modal__body {
        padding: 0 20px;

        @include mq($until: mobile) {
            max-height: unset;
        }
    }

    .modal__header {
        padding-bottom: 12px;
        font-size: 29px;
        line-height: 36px;
        color: $secondary-font-color;
        align-items: center;

        @include mq($until: mobile) {
            padding-left: 15px;
            padding-right: 42px;
        }
    }

    .modal__footer {
        padding-top: 42px;
        padding-bottom: 40px;

        @include mq($until: mobile) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .modal__close {
        right: 20px;
        top: 32px;
        color: $secondary-font-color;
    }

    &__btn {
        width: 184px;
    }
}
