.datepicker {
    border: none;
    display: flex;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, .75);

    &__input {
        background: #fff;
        border-radius: 10px;
        padding: 10px 35px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
        width: 260px;

        &_empty {
            color: $minor-font-color;
        }
    }

    &__clearButton:after {
        background-color: $secondary-font-color;
    }

    .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
        background-color: $secondary-font-color;
    }
}
