.result-screen {
    @include stretch;
    position: fixed;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto 1fr auto;
    background-color: $primary-bg-color;
    z-index: 100;
    height: 100%;

    @include mq($until: tablet) {
        overflow-y: auto;
    }

    &--success {
        .result-screen__indicator {
            color: $success-color;
        }
    }

    &--fail {
        .result-screen__indicator {
            color: $unsuccess-light-color;
        }
    }

    &__head {
        display: flex;
        justify-content: center;
        padding-top: 60px;

        > a {
            display: flex;
        }

        svg {
            font-size: 10vh;
            max-height: 90px;
            opacity: 0;
            animation: fade-in $main-transition forwards .1s;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        text-align: center;
        opacity: 0;
        animation: fade-in $main-transition forwards .2s;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__indicator {
        margin-bottom: 30px;

        svg {
            font-size: 44px;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;

        svg {
            font-size: 32vh;
            opacity: 0;
            animation: fade-in $main-transition forwards .1s;
        }
    }

    &__title {
        font-size: 36px;
        font-weight: 900;
        line-height: 45px;
        margin: 0 0 7px;
    }

    &.error-screen {
        a {
            color: $secondary-font-color;
        }

        .error-screen__actions {
            margin-top: 16px;
        }
    }
}
