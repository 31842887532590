.talent-top-visitors {
    @include friendlySectionAppear(.3s);
    background-color: transparent;

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        justify-content: center;
        width: 100%;
        grid-gap: 30px;
        justify-items: center;
    }

    &__item {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 0;
        max-width: 108px;

        img {
            height: 100%;
            max-width: 100%;
            object-fit: contain;

            &.is-failed {
                &,
                & + svg {
                    display: none;
                }
            }
        }
    }

    &__name {
        color: $secondary-font-color;
        font-weight: 800;
        text-align: center;
        font-size: 18px;
        overflow-wrap: break-word;
        min-width: 0;
    }
}
