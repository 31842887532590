.history-table {
    border: none;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    margin: 0;

    & th {
        color: $secondary-font-color;
        text-align: left;
        padding: 0 5px 10px;
        border-bottom: 1px solid $minor-font-color;
    }

    td {
        padding: 10px 5px;
    }

    tr &__centered-cell {
        text-align: center;
    }
}
