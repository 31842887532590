.profile-sidebar {
    &__inner {
        margin: 0;
        overflow: hidden;
        max-height: initial;
        // position: sticky;
        // max-height: calc(100vh - var(--header-height) + 40px);
        // top: calc(var(--header-height) + 40px);
        // overflow: hidden;
        background-color: transparent;
        border-radius: 0;
        // flex-direction: column;
    }

    &__main-content {
        // position: sticky;
        // max-height: calc(100vh - var(--header-height) + 40px);
        // top: calc(var(--header-height) + 40px);
        // overflow: hidden;
        background-color: $secondary-bg-color;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
    }

    &__heading {
        margin: 26px 30px 0;

        &:only-child {
            margin-bottom: 30px;
        }

        .user-avatar__image {
            font-size: $sidebar-user-avatar-size;
            margin: 0 auto;
        }

        .actions-badge-wrapper {
            width: $sidebar-user-avatar-size;
            height: $sidebar-user-avatar-size;
            margin: 0 auto;
        }

        .actions-badge {
            transform: translate3d(-8px, 8px, 0);
        }
    }

    &__name {
        margin-top: 50px;
        text-align: center;

        h2 {
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 6px;
        }
    }

    &__user-rank {
        margin-top: 6px;
        font-size: 12px;
        text-transform: capitalize;
    }

    &__progress {
        margin: 27px 30px;

        h3 {
            margin: 0 0 12px;
            font-size: 14px;
            font-weight: 800;
            text-align: center;
        }
    }

    &__progress-header {
        @include mq($until: tablet) {
            display: none;
        }
    }
}
