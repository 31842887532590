.rc-slider {
    @include friendlySectionAppear;
    position: relative;
    padding: 5px 0;
    width: 100%;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: calc(#{$range-input-track-height} + 5px * 2);
    margin-bottom: 18px;

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

.rc-slider-rail,
.rc-slider-track {
    height: $range-input-track-height;
    position: absolute;
}

.rc-slider-rail {
    width: 100%;
    background-color: $range-input-rail-color;
}

.rc-slider-track {
    left: 0;
    background-color: $range-input-track-color;
}

.rc-slider-handle {
    position: absolute;
    cursor: grab;
    touch-action: pan-x;
    top: 0;
    bottom: 0;
    margin: auto;
    width: $range-input-handle-width;
    height: $range-input-handle-height;
    border-radius: 8px;
    border: solid 1px #707070;
    background-color: $secondary-font-color;

    &:focus {
        outline: none;
    }

    &:active {
        cursor: grabbing;
    }
}

.rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
}

.rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
    font-size: 10px;
    transition: color $main-transition;
}

.rc-slider-mark-text-active {
    color: $primary-font-color;
}

.rc-slider-step {
    position: absolute;
    width: 100%;
    height: $range-input-track-height;
    background: transparent;
}

.rc-slider-dot {
    position: absolute;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    bottom: 0;
    top: 0;
    margin: auto auto auto calc((#{$range-input-track-height} + 2px) / 2 * -1);
    width: calc(#{$range-input-track-height} + 2px);
    height: calc(#{$range-input-track-height} + 2px);
}

.rc-slider-dot-active {
    border-color: $range-input-track-color;
}

.rc-slider-dot-reverse {
    margin-right: -4px;
}

.rc-slider-disabled {
    background-color: transparent;

    .rc-slider-tooltip-inner {
        opacity: .6;
    }

    .rc-slider-track {
        background-color: #ccc;
    }

    .rc-slider-handle,
    .rc-slider-dot {
        border-color: #ccc;
        box-shadow: none;
        background-color: #fff;
        cursor: not-allowed;
    }

    .rc-slider-mark-text,
    .rc-slider-dot {
        cursor: not-allowed;
    }
}

.rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;

    .rc-slider-rail {
        height: 100%;
        width: 4px;
    }

    .rc-slider-track {
        left: 5px;
        bottom: 0;
        width: 4px;
    }

    .rc-slider-handle {
        margin-left: -5px;
        touch-action: pan-y;
    }

    .rc-slider-mark {
        top: 0;
        left: 18px;
        height: 100%;
    }

    .rc-slider-step {
        height: 100%;
        width: 4px;
    }

    .rc-slider-dot {
        left: 2px;
        margin-bottom: -4px;

        &:first-child {
            margin-bottom: -4px;
        }

        &:last-child {
            margin-bottom: -4px;
        }
    }
}


.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block;
    animation-play-state: paused;
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(.23, 1, .32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
}

@keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }

    100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
}

@keyframes rcSliderTooltipZoomDownOut {
    0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }

    100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
}

.rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

.rc-slider-tooltip-hidden {
    display: none;
}

.rc-slider-tooltip-placement-top {
    padding: 0;

    .rc-slider-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
}

.rc-slider-tooltip-inner {
    text-align: center;
    text-decoration: none;
    padding: 0 2px;
    height: 18px;
    color: $secondary-font-color;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    display: none;
}
