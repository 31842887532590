.message-field {
    .floating-label__textarea {
        @include customScrollbars;
        resize: none;
        transition: none;
        overflow-y: auto;
        padding-right: 27px;

        &:disabled {
            cursor: default;
        }
    }

    label {
        top: 4px;
    }

    button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 18px;
        padding: 0;
        transition: all $main-transition;

        &:disabled {
            color: #ecf2f8;
            cursor: default;
        }
    }
}
