.notifications {
    &__item {
        @include friendlySectionAppear(.1s);
        padding: 18px 0;
        border-bottom: 1px solid rgba($secondary-font-color, .11);

        time {
            display: inline-block;
            margin-top: 18px;
            font-size: 10px;
            line-height: 14px;
            color: #c5c8ce;
        }
    }

    &__content {
        white-space: pre-wrap;

        em {
            color: $secondary-font-color;
            font-weight: 700;
            font-style: normal;
        }
    }
}
