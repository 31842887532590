.enterprise-congratulation-page {
    &__message {
        margin-top: 30px;
        margin-bottom: 48px;
    }

    .result-screen {
        z-index: 1;

        @include mq($until: tablet) {
            height: auto;
        }

        @include mq($until: mobile) {
            bottom: 60px;
        }

        &__title {
            @include mq($until: mobile) {
                font-size: 28px;
            }
        }

        &__head,
        &__footer {
            display: none;
        }

        &__footer {
            @include mq($until: tablet) {
                display: flex;
            }
        }

        &__body {
            margin-top: 168px;
        }
    }
}
