@mixin activeLabel() {
    top: -15px;
    font-size: 8px;
    line-height: 11px;
    color: $secondary-font-color;
}

.floating-label {
    position: relative;
    margin-top: 35px;
    width: 100%;

    &.with-icon {
        input {
            padding-right: 20px;
        }
    }

    &.hide-placeholder-on-focus {
        .floating-label__input {
            &::placeholder {
                opacity: 0;
                transition: opacity .05s $main-cubic-bezier-fn;
            }
        }
    }

    &__border {
        position: absolute;
        bottom: 1px;
        width: 100%;
        height: 1px;
        opacity: 0;
        pointer-events: none;
        color: $form-active-border-color;
        background-color: currentColor;
        transition: opacity .2s ease;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        max-width: 17px;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        transition: all $main-transition;
        color: $form-active-border-color;
    }

    > label {
        @include ellipsis;
        color: $minor-font-color;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        transition: all .2s ease;
        will-change: opacity;
    }

    & > &__error {
        position: absolute;
        top: calc(100% + 2px);
        color: $unsuccess-color;
        font-size: 9px;
        line-height: 11px;
        animation: fade-in $main-transition;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    & > &__input {
        padding: 4px 0 10px;
        display: block;
        width: 100%;
        height: 30px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $form-border-color;

        &:focus:not(&:read-only) {
            outline: none;
            border-color: $form-active-border-color;

            &::placeholder {
                transition-duration: .3s;
                opacity: 1;
            }

            ~ label {
                @include activeLabel;
            }

            ~ .floating-label__border,
            ~ .floating-label__icon {
                opacity: 1;
            }
        }

        &:not(:placeholder-shown) {
            ~ label {
                @include activeLabel;
            }
        }

        &:disabled,
        &:read-only {
            opacity: .4;
            cursor: not-allowed;

            ~ label {
                opacity: .4;
                cursor: not-allowed;
            }
        }
    }

    & > &__textarea {
        min-height: 30px;
        max-height: 160px;
        max-width: 100%;
        resize: vertical;
        overflow: hidden;
        overflow-x: hidden;
    }

    & > &__quill-editor {
        padding: 4px 0 10px;
        display: block;
        width: 100%;
        height: 200px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $form-border-color;
        min-height: 200px;
        max-width: 100%;
        resize: vertical;
        overflow: hidden;
        overflow-x: hidden;
    }

    &.is-required {
        label {
            &:not(:empty):after {
                content: "*";
                display: inline;
            }
        }
    }

    &.is-invalid {
        .floating-label__textarea,
        .floating-label__input {
            border-color: $form-error-border-color;

            &:focus {
                ~ label {
                    color: $unsuccess-color;
                }

                ~ .floating-label__border,
                ~ .floating-label__icon {
                    color: $form-error-border-color;
                }
            }
        }

        .floating-label__input {
            &:not(:placeholder-shown) {
                ~ label {
                    color: $unsuccess-color;
                }
            }
        }
    }
}
