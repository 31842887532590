.tooltip {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    pointer-events: none;
    -webkit-overflow-scrolling: touch;
    top: -70px;
    transform: translateY(70px);
    padding-bottom: 70px;

    &__inner {
        position: absolute;
        z-index: 10;
        max-width: $tooltip-max-width;
        min-width: $tooltip-min-width;
        max-height: 100%;
        background-color: $tooltip-bg;
        border-radius: 7px;
        opacity: 0;
        animation: scale-in .15s cubic-bezier(0, 0, .3, 1) forwards;
        pointer-events: auto;
    }

    &__arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-width: 0 7px 6px 6px;
        border-color: transparent transparent $tooltip-bg transparent;
        border-style: solid;
        z-index: 10;
        opacity: 0;
        animation: fade-in .3s linear .3s forwards;
        pointer-events: auto;

        &.position-top {
            transform: rotate(.5turn);
        }
    }

    &__content {
        font-size: 14px;
        line-height: 20px;
        padding: 15px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        animation: fade-in .2s cubic-bezier(0, 0, .3, 1) .2s forwards;
    }

    &--bottom-left-alignment {
        transform-origin: top left;
    }

    &--bottom-right-alignment {
        transform-origin: top right;
    }

    &--top-left-alignment {
        transform-origin: bottom left;
    }

    &--top-right-alignment {
        transform-origin: bottom right;
    }
}
