.chats {
    &__message-list {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
    }

    &__message-item {
        @include friendlySectionAppear(.1s);
        margin: 14px 0;
        display: grid;
        column-gap: 17px;
        row-gap: 6px;

        grid-template-columns: auto 1fr;
        grid-template-areas:
            "avatar message"
            ". timestamp";

        &--sent-by-me {
            grid-template-columns: 1fr auto;
            grid-template-areas:
                "message avatar"
                "timestamp .";

            .chats__message-time {
                text-align: start;
            }
        }
    }

    &__message-item .user-avatar__image {
        grid-area: avatar;
        font-size: $chat-avatar-size;
    }

    &__message-content {
        grid-area: message;
        background-color: $aux-bg-color;
        border-radius: 7px;
        padding: 10px 14px;
        font-size: 16px;
        white-space: pre-wrap;

        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;

        hyphens: auto;
    }

    &__message-time {
        grid-area: timestamp;
        font-size: 10px;
        color: #b2b2b2;
        text-align: end;
    }
}
