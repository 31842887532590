@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scale-in {
    0% {
        opacity: 0;
        transform: scale3d(0, 0, 0);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-from-bottom {
    0% {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-from-top {
    0% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-from-left {
    0% {
        transform: translate3d(-20px, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-from-right {
    0% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-bottom {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
    }
}

@keyframes slide-top {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
}

@keyframes slide-left {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(-20px, 0, 0);
        opacity: 0;
    }
}

@keyframes slide-right {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }
}

@keyframes reveal-from-bottom-with-scale {
    0% {
        transform: translate3d(0, 40px, 0) scale3d(.97, .97, 1);
    }

    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
}

@keyframes reveal-from-right {
    0% {
        transform: translate3d(60px, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes reveal-from-left {
    0% {
        transform: translate3d(-60px, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes reveal-with-scale {
    0% {
        transform: scale3d(.8, .8, .8);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}



.is-animated {
    transition: all $main-transition;
}

.fade-in {
    opacity: 0;
    animation: fade-in $main-transition forwards;
}

.fade-out {
    opacity: 0;
    animation: fade-out $main-transition forwards;
}

.slide-from-bottom {
    opacity: 0;
    animation: slide-from-bottom $main-transition forwards;
}

.slide-from-top {
    opacity: 0;
    animation: slide-from-top $main-transition forwards;
}

.slide-from-left {
    opacity: 0;
    animation: slide-from-left $main-transition forwards;
}

.slide-from-right {
    opacity: 0;
    animation: slide-from-right $main-transition forwards;
}

.slide-bottom {
    opacity: 0;
    animation: slide-bottom $main-transition forwards;
}

.slide-top {
    opacity: 0;
    animation: slide-top $main-transition forwards;
}

.slide-left {
    opacity: 0;
    animation: slide-left $main-transition forwards;
}

.slide-right {
    opacity: 0;
    animation: slide-right $main-transition forwards;
}
