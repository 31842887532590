.wizard {
    @include friendlySectionAppear;
    height: 100%;
    padding: 0 30px;

    @include mq($until: mobile) {
        padding: 0;
        background-color: $secondary-bg-color;
    }

    &__content {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: $wizard-sidebar-width 1fr;
        padding: 40px 0 35px;

        @include mq($until: tablet) {
            display: block;
        }

        @include mq($until: mobile) {
            padding: 0;
            margin-bottom: $mobile-user-area-height;
        }
    }
}

.wizard-steps {
    overflow: hidden;

    @include unique {
        grid-column: 1 / -1;
    }
}

.wizard-step {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        @include mq($until: mobile) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

    }

    &.is-edit-mode {
        .floating-label,
        .custom-select {
            @include hideFloatingLabelAnimation;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        margin-top: 60px;
        min-height: 40px;

        @include mq($until: tablet) {
            justify-content: space-between;
            padding: 0 19px;
            margin-bottom: 20px;
        }

        @include mq($until: tiny) {
            .wizard-step__back-btn {
                margin: 0;
            }
        }

    }

    &__inner {
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 30px 60px;
        min-height: 530px;
        margin-bottom: auto;
        opacity: 0;

        @include mq($until: mobile) {
            min-height: 380px;
            padding: 30px 19px 0;
        }
    }

    &__name {
        font-size: 24px;
        margin: 0 0 18px;
        font-weight: 900;

        @include mq($from: tablet) {
            display: none;
        }
    }

    &__title {
        margin: 0 0 52px;
        font-size: 24px;
        font-weight: 900;
        line-height: 36px;

        + .wizard-step__title-description {
            margin-top: -52px;
        }

        @include mq($until: tablet) {
            font-size: 22px;
            margin-bottom: 30px;
            font-weight: 400;

            &:only-child {
                font-weight: 900;
            }
        }
    }

    &__title-placeholder {
        height: 28px;

        @include mq($from: mobile) {
            height: 0;
        }
    }

    &__title-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
    }

    &__submit-btn {
        width: 340px;
        margin-left: auto;

        @include mq($until: mobile) {
            width: 200px;

            &:only-child {
                margin: auto;
            }
        }
    }

    &__secondary-btn,
    &__success-btn {
        width: 184px;
    }

    &__skip-btn,
    &__back-btn {
        display: flex;
        align-items: center;
        line-height: 20px;

        @include mq($until: mobile) {
            margin: auto;
        }

        svg {
            transform: translateY(1px);
            font-size: 12px;
            margin-right: 18px;
            z-index: -1;
        }
    }

    &__skip-btn {
        padding-right: 0;

        svg {
            transform: rotate(.5turn);
            margin-left: 18px;
            margin-right: 0;
        }
    }

    &__form-row {
        display: flex;
        justify-content: space-between;

        @include after-first(1) {
            margin-top: 35px;
        }

        @include mq($until: desktop) {
            flex-direction: column;
        }

        .custom-select,
        .floating-label {
            margin-top: 0;
        }
    }

    &__form-cell {
        width: 100%;
        max-width: 292px;
        display: flex;

        @include mq($from: desktop) {
            @include after-first(1) {
                margin-left: 30px;
            }
        }

        @include mq($until: desktop) {
            @include after-first(1) {
                &:not(:empty) {
                    margin-top: 35px;
                }
            }
        }

        @include mq($until: desktop) {
            max-width: unset;
        }

        @include unique {
            @include mq($from: desktop) {
                max-width: none;
            }
        }
    }

    &__actions-row {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        > .btn.account-settings__save-btn {
            margin-top: 0;
        }

        &--reverse {
            flex-direction: row-reverse;
        }

        @include mq($until: tiny) {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > * {
                margin: 10px 0;
            }
        }
    }

    &__main-btn-section {
        position: relative;

        @include mq($until: tablet) {
            &:only-child {
                margin: auto;
            }
        }
    }

    &__hint-section {
        width: 100%;
        position: relative;
    }

    &__btn-tip-container {
        display: flex;
        align-items: center;

        @include mq($until: small) {
            margin-right: -38px;
        }

        .tip {
            margin-left: 30px;

            @include mq($until: small) {
                margin-left: 15px;
            }
        }
    }

    .account-settings__save-btn {
        margin-top: 50px;
    }
}
