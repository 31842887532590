.progress-bar {
    width: 100%;
    height: 9px;

    appearance: none;
    border: none;
    border-radius: 7px;
    overflow: hidden;

    &::-webkit-progress-bar {
        background-color: $form-value-tag-bg;
    }

    &::-webkit-progress-value {
        background-image: linear-gradient(90deg, $success-color 0%, $success-color 50%, transparent 50%);
        background-color: transparent;
        background-size: 4px 20px;
    }

    &::-moz-progress-bar {
        background-image: linear-gradient(90deg, $success-color 0%, $success-color 50%, transparent 50%);
        background-color: transparent;
        background-size: 4px 20px;
    }

    &--indeterminate {
        @include friendlySectionAppear;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $loader-backdrop-bg;
        display: none;

        &, &:before {
            height: 3px;
            width: 100%;
            margin: 0;
        }

        &:before {
            background-color: $secondary-font-color;
            content: "";
            animation: running-progress 2s cubic-bezier(.4, 0, .2, 1) infinite;
        }

        @keyframes running-progress {
            0% { margin-left: 0; margin-right: 100%; }

            50% { margin-left: 25%; margin-right: 0%; }

            100% { margin-left: 100%; margin-right: 0; }
        }
    }
}
