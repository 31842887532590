.geolocation {
    &-pin-button {
        background-color: transparent;
        color: $form-active-border-color;
        opacity: 0;
        transition: opacity $main-transition;
        font-size: 18px;

        &:hover {
            color: $form-active-border-color;
            opacity: 1;
        }

        .custom-select__control--is-focused & {
            opacity: 1;
        }
    }
}

