.pricing-card {
    max-width: 300px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &_active {

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .75);
    }

    @include mq($until: mobile) {
        max-width: unset;
    }

    &__top-line {
        width: 100%;
        height: 10px;
    }

    &__title {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
    }

    &__interval {
        font-size: 22px;
        font-weight: 700;
    }

    &__price {
        font-size: 56px;
        line-height: .7;
        margin-bottom: 24px;

        &__dollar {
            font-size: 18px;
            vertical-align: top;
        }

        &__decimal {
            font-size: 18px;
        }
    }

    &__users {
        color: $secondary-font-color;
        font-size: 18px;
    }

    &__info-list {
        margin-bottom: 30px;

        &__item {
            position: relative;
            text-align: left;
            gap: 10px;
            list-style-type: none;
            font-size: 18px;
            padding: 5px 0 0 10px;

            &:before {
                position: absolute;
                right: 100%;
                content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTAgMTkwIiBzdHlsZT0iZmlsbDogIzkyYzZmYiAhaW1wb3J0YW50OyBjb2xvcjogIzkyYzZmYiAhaW1wb3J0YW50O3RyYW5zZm9ybTogcm90YXRlKGRlZykgIWltcG9ydGFudDsiPjxwb2x5Z29uIHBvaW50cz0iMTczLjgsMjguNCA2MC40LDE0MS44IDE1LjcsOTcuMiA1LjEsMTA3LjggNjAuNCwxNjMgMTg0LjQsMzkgMTczLjgsMjguNCIgZmlsbD0iIzkyYzZmYiIgc3Ryb2tlPSIjOTJjNmZiIiBzdHlsZT0iZmlsbDogcmdiKDE0NiwgMTk4LCAyNTEpOyBzdHJva2U6IHJnYigxNDYsIDE5OCwgMjUxKTsiLz48L3N2Zz4=");
                display: block;
                width: 14px;
                height: 100%;
            }
        }
    }

    &__button {
        height: unset;
        border-radius: 0;
        text-transform: none;
        padding: 12px 16px;

        &:hover {
            transform: scale(1.05);

        }
    }
}
