.talent-summary-card {
    @include friendlySectionAppear;
    display: grid;
    grid-template-columns: 82px 1fr 74px;
    grid-column-gap: 20px;
    min-height: 131px;
    background-color: #ecf2f8;
    border-radius: 7px;
    padding: 20px;

    &:hover {
        cursor: pointer;
    }

    @include after-first(1) {
        margin-top: 16px;
    }

    @include mq($until: tablet) {
        grid-template-columns: none;
        grid-template-areas:
            "u a"
            "i i";
    }

    button {
        padding: 0;
        display: inline-flex;

        &:hover {
            cursor: pointer;
        }
    }

    &__col {
        @include first-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq($until: tablet) {
                grid-area: u;
                flex-direction: row;
                justify-content: flex-start;
            }
        }

        &:nth-child(2) {
            display: grid;
            grid-gap: 17px 20px;
            grid-template-columns: 1fr 1fr 1fr;

            @include mq($until: tablet) {
                grid-area: i;
                margin-top: 20px;
                grid-gap: 20px 5px;
            }
        }

        @include last-child {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;

            @include mq($until: tablet) {
                grid-area: a;
            }
        }
    }

    &__user-image {
        width: $talent-summary-card-avatar-size;
        height: $talent-summary-card-avatar-size;
        position: relative;
        flex-shrink: 0;

        .user-avatar__image {
            font-size: $talent-summary-card-avatar-size;
        }
    }

    &__fullname-wrapper {
        @include mq($until: tablet) {
            padding-left: 9px;
            text-align: start;
            align-self: flex-end;
        }
    }

    &__fullname {
        margin-top: 14px;
        word-break: break-word;
        max-width: 100%;
        text-align: center;

        @include mq($until: tablet) {
            margin: 0 6px 8px;
            text-align: start;
        }
    }

    &__fullname,
    &__field-title {
        font-size: 12px;
        line-height: 15px;
        font-weight: 800;
        color: $secondary-font-color;
    }

    &__field {
        width: 100%;
    }

    &__field-content {
        margin-top: 4px;
        font-size: 14px;
        line-height: 18px;
        overflow-wrap: break-word;
        max-width: 100%;

        @include mq($until: tiny) {
            font-size: 12px;
        }


        &:empty {
            height: 18px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
    }

    &__note-btn {
        font-size: 20px;
        color: $secondary-font-color;

        + .heart-btn {
            margin-left: 20px;
        }
    }

    &__match-score {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        font-weight: 700;

        span {
            font-size: 27px;
            line-height: 27px;
            color: $success-color;
        }
    }

    &__compare {
        height: 13px;
        display: flex;
        align-items: center;

        .custom-checkbox {
            &__checkbox {
                width: 13px;
                height: 13px;

                &:after {
                    width: 9px;
                    height: 9px;
                }
            }

            &__label {
                margin-left: 0;
            }

            > label {
                padding: 5px;
            }
        }

        > button {
            height: 23px;
            width: auto;
            align-items: center;
            flex-shrink: 0;
            text-transform: none;
            border-radius: 0;
            font-size: 12px;
            line-height: 13px;
            font-weight: 700;
            padding: 0 0 0 5px;

            &,
            &:hover,
            &:active {
                color: $success-color;
            }
        }
    }

    &__applied-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        gap: 5px;

        svg {
            font-size: 20px;
        }
    }
}
