.hh-pool-form-sidebar {
    .sidebar-nav {
        &__item-label {
            grid-column: 1 / 3;
            color: $primary-font-color;

            span {
                cursor: pointer;
            }
        }
    }
}
