.notifications-drawer {
    &__back-btn {
        margin-right: 12px;
    }

    &__messages-area {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__list-holder {
        flex: 1;
        min-height: 0;
        position: relative;

        &:before {
            @include stretch;
            content: "";
            bottom: auto;
            right: 5px;
            z-index: 10;
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            height: 20px;
            pointer-events: none;
        }

        &:after {
            @include stretch;
            content: "";
            top: auto;
            right: 5px;
            background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            height: 35px;
            pointer-events: none;
        }
    }

    &__list {
        @include customScrollbars;
        height: 100%;
        overflow-y: auto;
        padding: 0 20px 30px;
        //scroll-behavior: smooth;

        .fetch-more-btn {
            border: none;
            margin: 20px auto 0;
            text-transform: none;
            padding: 0;
            height: auto;

            &:hover,
            &:active {
                cursor: pointer;
                background-color: transparent;
                color: $secondary-font-color;
            }
        }
    }
}
