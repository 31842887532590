.enterprise-talent-note {
    @include friendlySectionAppear(.1s);
    padding: 18px 0;
    border-bottom: 1px solid rgba($secondary-font-color, .11);

    &__text {
        white-space: pre-wrap;
    }

    time {
        display: inline-block;
        margin-top: 18px;
        font-size: 10px;
        line-height: 14px;
        color: #c5c8ce;
    }
}
