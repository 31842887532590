.compare-talents-modal {
    max-width: 100%;
    background-color: transparent;

    @include mq($until: desktop) {
        background-color: $secondary-bg-color;
    }

    &__body {
        max-height: 100vh;
        padding: 0;
    }

    &__backdrop-helper {
        width: 100%;
        padding: 0 55px 50px;

        @include mq($until: desktop) {
            padding: 0;
        }
    }

    &__body-inner {
        max-width: 1100px;
        margin: 0 auto;
    }

    &__head {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 63px 0 33px;

        @include mq($until: desktop) {
            padding: 30px;
            color: $primary-font-color;
        }
    }

    &__title {
        color: inherit;
        line-height: 35px;
        font-size: 24px;
        margin: 0;
    }

    &__close {
        position: static;
        color: inherit;
        box-shadow: none;
        background-color: unset;
    }

    + .modal__backdrop {
        background-color: opacify($modal-backdrop-bg, .3);
    }
}
