.wizard-countdown-timer {
    &__digit-counter {
        font-size: 80px;
        font-weight: 700;
        color: $secondary-font-color;

        &--warning {
            color: $unsuccess-color;
        }
    }

    &__countdown-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__trail {
        stroke: $form-border-color;
        stroke-width: 1px;
    }

    &__slices {
        stroke: $secondary-bg-color;
        stroke-linecap: butt;
        stroke-width: 14px;
        stroke-dasharray: 2.5;
    }
}
