.talent-sidebar {
    &__top-quality {
        @include friendlySectionAppear;
        margin: 30px 15px 0;
        border-top: 1px solid $sidebar-nav-item-bg;
        padding: 24px 10px 0;
        text-align: center;

        &:last-child {
            margin-bottom: 22px;
        }

        h3 {
            margin: 0 0 6px;
            font-size: 16px;
            font-weight: 800;
            text-transform: capitalize;
        }

        li,
        p {
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
}
