.certificates-step {
    .wizard-step__form-cell {
        &:nth-child(2) {
            max-width: 323px;
        }

        .input-group--month-year {
            > * {
                @include mq($from: desktop, $until: 1133px) {
                    @include after-first(1) {
                        margin-left: 8px;
                    }
                }
            }

            @include mq($until: 362px) {
                flex-wrap: wrap;
                grid-gap: 10px;
                justify-content: initial;
            }
        }
    }
}
