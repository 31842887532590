@keyframes revealComparePopup {
    from {
        transform: translate3d(100%, 0, 0);
    }
}

.talent-compare-popup {
    position: fixed;
    right: 0;
    bottom: 40px;
    width: 418px;

    @include mq($until: tiny) {
        width: 100%;
    }

    @include mq($until: mobile) {
        bottom: 60px;
    }

    &__inner {
        position: relative;
        width: 100%;
        padding: 20px 35px 30px 20px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .06);
        background-color: #fff;
        display: grid;
        grid-template-rows: 90px auto 43px;
        grid-row-gap: 28px;
        border-radius: 7px 0 0 7px;
        transform: translate3d(0, 0, 0);
        animation: revealComparePopup .5s cubic-bezier(.22, 1, .36, 1) forwards;
    }

    &__error-modal {
        .common-modals__body {
            justify-content: center;
        }

        .common-modals__title {
            margin-bottom: 10px;
        }

        .common-modals__content {
            display: none;
        }
    }

    &__head {
        padding-right: 25px;

        h2 {
            color: $primary-font-color;
            margin: 0;
            font-size: 24px;
            font-weight: 800;
        }
    }

    &__rules {
        margin-top: 20px;
    }

    &__close-btn {
        position: absolute;
        right: 35px;
        top: 35px;
        font-size: 20px;
        display: inline-flex;
        padding: 0;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > button {
            width: 186px;
        }
    }

    &__count {
        font-size: 18px;
        line-height: 24px;

        span {
            font-weight: 800;
            color: $secondary-font-color;
        }
    }

    &__item {
        display: flex;
        align-items: center;

        @include after-first(1) {
            margin-top: 15px;
        }

        .user-avatar__image {
            flex-shrink: 0;
            font-size: $compare-popup-avatar-size;
        }
    }

    &__item-fullname {
        margin-left: 15px;
        color: $secondary-font-color;
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        flex: 1;
        overflow-wrap: break-word;
    }

    &__item-match-score {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 11px;
        font-weight: 700;
        padding-left: 10px;

        span {
            font-size: 22px;
            line-height: 22px;
            font-weight: 800;
            color: $success-color;
        }
    }

    &__remove-item-btn {
        font-size: 9px;
        color: $attention-color;
        flex-shrink: 0;
        display: inline-flex;
        padding: 5px;
        margin-left: 15px;
    }
}
