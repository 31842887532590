.enterprise-dashboard-page {
    padding: 30px;
    min-height: 100%;
    width: 100%;

    @include mq($until: mobile) {
        padding: 0 19px 30px;
        margin-bottom: $mobile-user-area-height;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($hh-pool-card-min-width, 1fr));
        grid-auto-rows: $hh-pool-card-height;
        grid-gap: 30px;

        @include mq($until: mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__empty-button {
            color: #1688f3;
            text-decoration: underline;
        }
    }

    .plus-btn {
        opacity: 0;
        animation: reveal-from-bottom-with-scale .6s cubic-bezier(.22, 1, .36, 1) .3s forwards, fade-in .3s linear forwards .3s;
    }

    &__add-btn {
        position: fixed;
        right: 57px;
        bottom: 39px;

        @include mq($until: mobile) {
            bottom: 99px;
        }
    }
}
