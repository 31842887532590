.search-talents-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px 43px;

    @include mq($until: desktop) {
        margin-bottom: $mobile-user-area-height;
        flex-direction: column;
        padding: 0 19px 30px;

        .talent-cards-result {
            margin-top: 0;
        }
    }

    .search-talent-form {
        margin-block-end: 0;
    }
}
