.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -70px;
    transform: translateY(70px);
    padding-bottom: 70px;

    @include mq($until: mobile) {
        top: 0;
        transform: translateY(0);
        padding-bottom: 0;
    }

    &__container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        pointer-events: none;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &__backdrop {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        background-color: $modal-backdrop-bg;
        opacity: 0;
        transition: opacity .3s linear;
    }

    &__inner {
        position: relative;
        z-index: 10;
        max-width: $modal-width;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        transform: translate3d(0, 60px, 0);
        background-color: $modal-bg;
        opacity: 0;
        transition: all $main-transition;
        transition-duration: .2s;
        transition-property: opacity, transform;

        &.is-open {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            pointer-events: auto;

            + .modal__backdrop {
                opacity: 1;
                pointer-events: auto;
                transition-duration: .2s;
            }
        }

        @include mq($until: mobile) {
            width: 100%;
            height: 100%;
            max-width: unset;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);

        @include mq($until: mobile) {
            grid-template-rows: min-content 1fr min-content;
            height: 100%;
        }
    }

    &__close {
        position: absolute;
        top: 42px;
        right: 34px;
        display: flex;
        color: $primary-font-color;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        background-color: $secondary-bg-color;
        box-shadow: 0 0 8px 10px $secondary-bg-color;

        svg {
            font-size: 20px;

            @include mq($until: small) {
                font-size: 16px;
            }
        }

        + .modal__body {
            max-height: calc(100vh - 124px);
            padding-top: 32px;
        }
    }

    &__header {
        text-align: center;
        padding: 32px 55px 34px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 30px;
        color: $primary-font-color;
        font-size: 24px;
        font-weight: 800;
        position: relative;

        @include mq($until: mobile) {
            padding: 30px 55px 35px 19px;
        }
    }

    &__footer {
        text-align: center;
        padding: 40px 55px 32px;

        @include mq($until: mobile) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    &__body {
        @include customScrollbars;
        padding: 0 55px;
        overflow-y: auto;
        max-height: calc(100vh - 220px);

        @include mq($until: mobile) {
            padding: 19px;
        }
    }
}
