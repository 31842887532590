.talent-setup {
    .talent-dashboard__heading {
        background: none;
        padding: 0;
    }

    &__heading-title {
        font-size: 30px;
        font-weight: 900;
        margin: 0;
        line-height: 38px;

        @include mq($until: desktop) {
            font-size: 18px;
        }
    }

    &__heading-subtitle {
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        margin: 0 0 31px;
        color: $primary-font-color;

        @include mq($until: desktop) {
            font-size: 18px;
            margin-bottom: 18px;
        }
    }

    &__cards {
        margin: 40px 0 40px;
        display: grid;
        grid-template-columns: repeat(3, 200px);
        grid-gap: 30px;

        @include mq($until: desktop) {
            display: flex;
            flex-direction: column;
        }
    }

    &__actions {
        margin-top: 40px;

        @include mq($until: desktop) {
            text-align: center;
        }

    }

    &__actions-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: calc(200px * 3 + 30px * 2);

        @include mq($until: desktop) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .delete-account-btn {
            margin-inline-start: 16px;

            @include mq($until: desktop) {
                margin-inline-start: 0;
                margin-block-start: 16px;
            }
        }
    }

    &__ready {
        @include mq($until: tablet) {
            display: none;
        }
    }

    &__action-btn {
        width: 184px;
    }

    &__card {
        position: relative;
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 10px 28px 28px;
        transition: box-shadow $main-transition;

        &:hover {
            box-shadow: 0px 6px 8px -4px #c5ddf8;
        }

        @include mq($until: desktop) {
            display: flex;
            justify-content: space-evenly;
        }
    }

    &__card-img {
        font-size: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__card-desc {
        margin: 20px 0 0 10px;
    }

    &__card-index {
        font-size: 28px;
        font-weight: 900;
        color: $secondary-font-color;

        @include mq($until: desktop) {
            position: absolute;
            top: 6px;
            left: 6px;
        }
    }

    &__card-title {
        font-size: 16px;
        font-weight: 800;
        line-height: 20px;
        color: $primary-font-color;
        margin: 0 0 9px;
    }

    &__card-duration {
        font-size: 12px;
        font-weight: 700;
        color: $secondary-font-color;
        margin: 0;
    }

    &__card-completed-mark {
        position: absolute;
        top: 8px;
        right: 9px;
        width: 20px;
        font-size: 20px;
        color: $success-color;

        > svg {
            opacity: 0;
            animation: fade-in .3s linear forwards;
        }
    }
}
