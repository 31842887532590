.cookie-usage {
    @include friendlySectionAppear(1s);

    position: fixed;
    bottom: 0;
    min-height: 75px;
    left: 0;
    right: 0;
    background-color: $secondary-bg-color;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, .05);
    z-index: 20;

    &__text {
        font-size: 16px;
        font-weight: 700;
        color: $primary-font-color;

        @include mq($until: mobile) {
            font-size: 14px;
        }
    }

    &__link {
        color: $secondary-font-color;
    }

    .home-page__btn {
        margin-left: 25px;
        min-width: 103px;

        @include mq($until: mobile) {
            margin-left: 15px;
            min-width: 76px;
        }
    }
}
