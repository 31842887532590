.sorting-with-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    width: 100%;

    &:disabled {
        opacity: 1;
    }

    &:not(:disabled):hover {
        .sorting-with-arrow__icon {
            opacity: .8;

            &.desc-first {
                transform: rotate(180deg);
            }
        }
    }

    &__label {
        font-size: 24px;
        font-weight: 700;
        color: #3b89eb;
        text-transform: capitalize;
        text-align: initial;
    }

    &__icon {
        flex-shrink: 0;
        flex-grow: 0;
        opacity: 0;
        transition: opacity $main-transition;

        &.sorted_by_asc,
        &.sorted_by_desc {
            opacity: 1;
        }

        &.sorted_by_desc {
            transform: rotate(180deg);
        }
    }
}
