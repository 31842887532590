.assessment {
    .sidebar__head {
        &:not(:only-child) {
            height: initial;
            padding-top: 29px;
            padding-bottom: 9px;
        }
    }

    .wizard-step__actions {
        position: relative;

        @include mq($until: small) {
            flex-direction: column-reverse;
            align-items: center;

            .wizard-step__submit-btn {
                margin-left: 0;
                margin-bottom: 29px;
            }
        }
    }

    &__question {
        margin-bottom: 60px;

        @include mq($until: mobile) {
            margin-bottom: 30px;
        }
    }

    &__question-index {
        margin: 0 0 28px;
        font-size: 20px;
        font-weight: 400;

        @include mq($until: tablet) {
            margin: 12px 0 0;
            font-weight: 700;
        }
    }

    &__question-text {
        font-weight: 300;
        margin-bottom: 24px;

        @include mq($until: tablet) {
            font-weight: 700;
        }
    }

    &__question-title {
        margin-bottom: 0;

        @include mq($until: tablet) {
            font-weight: 400;
        }
    }

    &__countdown-container {
        display: flex;
        justify-content: center;
    }

    &__error-alert {
        color: $unsuccess-color;
    }

    &__submit-error {
        right: 0;
    }

    @include mq($until: tablet) {
        .wizard-sidebar__content {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding-bottom: 0;
            padding-top: 40px;
        }
    }


    @include mq($until: mobile) {
        .wizard-step__submit-btn {
            width: 184px;
        }
    }
}
