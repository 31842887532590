.talent-comparison-chart {
    @include friendlySectionAppear(.2s);
    width: 450px;
    height: 300px;
    margin: 20px auto 20px;

    @include mq($until: small) {
        max-width: 450px;
        width: 100%;
        height: 280px;
    }

    @include mq($from: tablet, $until: desktop) {
        width: 430px;
    }

    &--fallback {
        height: 336px;
    }

    &__legend {
        @include friendlySectionAppear(.2s);
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }

    &__legend-item {
        font-size: 12px;
        color: $secondary-font-color;
        min-width: 0;

        @include after-first(1) {
            margin-left: 30px;
        }
    }

    &__legend-label {
        text-transform: capitalize;
        overflow-wrap: break-word;
    }

    &__legend-indicator {
        display: inline-block;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: #2d96fc;
        margin-right: 10px;

        &--secondary {
            background-color: #51cb20;
        }
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        display: block;
        pointer-events: all;
        cursor: pointer;

        @include mq($until: small) {
            font-size: 12px;
        }

        svg {
            margin-left: -3px;
            font-size: 14px;
            opacity: 0;

            @include mq($until: small) {
                font-size: 12px;
            }
        }

        &:hover {
            svg {
                opacity: 1;
            }
        }
    }
}
