.success-payment {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    &__inner {
        background-color: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    &__title {
        text-align: center;
        margin: 0;
    }

    &__content {
        font-size: 22px;
        text-align: center;
        margin: 0;
    }
}
