.job-post-sidebar {
    &__inner {
        max-height: initial;

        @include mq($until: desktop) {
            background-color: transparent;
        }
    }

    &__number-of-job-posts {
        @include friendlySectionAppear(.2s);

        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        min-height: 32px;
        margin: 0;
    }

    &__title {
        font-size: 24px;
        line-height: 32px;
        min-height: 32px;
        margin: 0;
    }

    &__heading {
        display: flex;
        align-items: baseline;
        gap: 5px;
    }

    &__actions {
        margin-top: 75px;
        display: flex;
        justify-content: center;
        padding: 0 30px;

        svg {
            transform: translateY(1px);
            font-size: 12px;
            margin-right: 18px;
            z-index: -1;
        }
    }

    .sidebar-nav {
        &__item-label {
            span {
                cursor: pointer;
            }
        }
    }

    .fetch-more-btn {
        border: none;
        margin: 20px auto 20px;
        text-transform: none;
        padding: 0;
        height: auto;

        &:hover {
            cursor: pointer;
            background-color: transparent;
            color: #1688f3;
        }
    }
}
