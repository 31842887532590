.pricing-page {
    height: 100%;

    &__container {
        margin: 0 auto;
        padding: 0 30px;
        max-width: 1180px;
    }

    &__description {
        font-size: 18px;
    }

    &__head {
        padding-top: 80px;
        text-align: center;
    }

    &__title {
        font-size: 42px;
        font-weight: 800;
        margin: 0 0 10px;
        color: #000;

        @include mq($until: mobile) {
            font-size: 30px;
        }
    }

    &__recruitment-button {
        border-radius: 50px;
        text-transform: capitalize;
        font-size: 18px;
        padding: 12px 50px;
        height: 50px;
    }

    &__recruitment-section {
        padding: 50px 30px;
        background-color: #edf2f7;
        text-align: center;
    }

    &__plans-wrapper {
        margin: 0 auto;
        padding: 60px 0;
    }

    &__plans-list {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: flex-start;

        @include mq($until: mobile) {
            flex-direction: column;
        }
    }

    &__trial-section {
        text-align: center;
        padding-bottom: 60px;
    }

    &__trial-button {
        height: unset;
        padding: 12px 30px;
        line-height: 1.6;
        border-radius: 50px;
        text-transform: none;
        background: linear-gradient(90deg, #eb307e, #1688f3);

        &:hover {
            background: linear-gradient(90deg, #1688f3, #eb307e);
        }
    }
}
