.relocation-step {
    .wizard-step {
        &__form-cell {
            flex-direction: column;
        }
    }

    .custom-radio {
        &__label {
            margin-left: 15px;
        }
    }

    &__countries-row {
        margin-top: 25px;
        display: flex;
        padding-left: 37px;
        transition: all .2s cubic-bezier(0, 0, .3, 1) .2s;
        transform: translate3d(0, -7px, 0);
        opacity: 0;

        &.is-expanded {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        @include mq($until: mobile) {
            padding-left: 0;
            font-size: 14px;
        }
    }
}
