.social-auth-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: $secondary-bg-color;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    font-size: 14px;
    font-weight: 400;
    height: 32px;
    letter-spacing: .25px;
    padding: 0 10px;

    border-radius: 4px;
    border: 1px solid #dadce0;

    @include onMouseDevices {
        &:hover {
            background-color: #f7fafe;
            box-shadow: none;
            border-color: #d2e3fc;
            outline: none;
        }
    }

    &__icon {
        display: flex;
    }

    &__title {
        flex-grow: 1;
        font-family: "Google Sans",arial,sans-serif;
        font-weight: 500;
    }

    svg {
        height: 14px;
        width: auto;
    }
}
