.talent-congratulation {
    @include mq($until: tablet) {
        .talent-dashboard__content {
            display: flex;
            flex-direction: column;
        }
    }

    .talent-dashboard__heading {
        position: relative;
        display: flex;
    }

    &__heading {
        @include mq($until: mobile) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding-bottom: 0;
            text-align: center;
        }

        @include mq($from: mobile, $until: tablet) {
            margin: 0;
            padding: 0;
        }
    }

    &__heading-rank-badge {
        align-self: center;
        margin-right: 24px;
        height: 100px;
        flex-basis: 125px;

        svg {
            font-size: 100px;
        }

        @include mq($until: tablet) {
            display: none;
        }
    }

    &__heading-title {
        font-size: 30px;
        font-weight: 800;
        margin: 0;
        line-height: 38px;
    }

    &__heading-subtitle {
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        margin: 0 0 31px;
        color: $primary-font-color;

        em {
            color: $secondary-font-color;
            font-weight: 800;
            font-style: normal;
            text-transform: capitalize;
        }

        > span {
            @include friendlySectionAppear;
        }
    }

    &__actions {
        margin: 30px 0 38px;

        @include mq($until: mobile) {
            text-align: center;
        }

        @include mq($from: mobile, $until: tablet) {
            margin-bottom: 0;
        }

    }

    &__dashboard-link {
        width: 270px;
    }
}
