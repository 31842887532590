$enterprise-edit-logo-size: 100px;

.enterprise-edit-logo {
    .actions-badge {
        margin: -8px 0 0 11px;
    }

    &__card {
        display: inline-block;
        min-width: $enterprise-edit-logo-size;
        height: $enterprise-edit-logo-size;
        border-radius: 7px;
        box-shadow: 0 3px 6px rgba(#000, .16);

        &.is-processing {
            .actions-badge__btn {
                cursor: default;
                pointer-events: none;
            }
        }

        .user-avatar__image {
            font-size: $enterprise-edit-logo-size;
            width: auto;
            max-width: 100%;
            padding: 5px;
        }

        .file-dropzone-upload__image--company-logo {
            margin-top: 0;
        }

        .file-dropzone-upload__image--only-image {
            height: $enterprise-edit-logo-size;
        }
    }

    &__card-icon {
        padding: 5px;
    }
}
