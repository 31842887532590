.talent-cv-summary {
    position: relative;
    margin-top: 30px;
    width: 100%;
    text-align: left;

    &:hover .section-edit-action {
        visibility: visible;
        opacity: 1;
    }

    .section-edit-action {
        position: absolute;
        top: 25px;
        right: 40px;
        visibility: hidden;
        opacity: 0;
        transition: opacity $main-transition;

        @include mq($until: tiny) {
            right: 10px;
        }

        @media (hover: none) {
            visibility: visible;
            opacity: 1;
        }

        &__button,
        &__link {
            $size: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            border-radius: 50%;
            width: $size;
            height: $size;
            background-color: $button-primary-bg;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: $button-primary-color;
                }
            }
        }
    }

    &__title {
        font-weight: 800;
    }

    &__content {
        line-height: 24px;
        word-break: break-word;
    }

    &__form {
        &__textaria {
            min-height: 76px;
            height: max-content;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            resize: vertical;
            line-height: 24px;
        }

        &__actions-wrapper {
            display: flex;
            grid-gap: 15px;
            margin-top: 10px;
            align-items: center;
            justify-content: flex-end;
        }

        &__error {
            color: $unsuccess-color;
            font-size: 9px;
            line-height: 11px;
            margin-top: 2px;
            animation: fade-in $main-transition;
        }
    }
}
