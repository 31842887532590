.talent-details {
    &__knowledge {
        display: flex;
        padding: 30px;

        @include mq($until: tiny) {
            padding: 30px 12px;
            flex-direction: column;
            align-items: stretch;
        }

        .wizard-items-list {
            &__item {
                border: none;
                padding: 0;

                @include after-first(1) {
                    margin-top: 20px;
                }
            }

            &__item-content {
                line-height: normal;
                font-size: 14px;
            }

            &__item-title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }

    &__knowledge-section {
        flex: 1;
        position: relative;

        &:hover .section-edit-action {
            visibility: visible;
            opacity: 1;
        }

        & + & {
            margin-left: 30px;

            @include mq($until: tiny) {
                margin-left: 0;
                margin-top: 30px;
            }
        }
    }

    &__knowledge-title {
        display: flex;
        grid-gap: 15px;
        justify-content: space-between;

        font-size: 24px;
        font-weight: 800;
        line-height: 30px;
        margin: 0 0 17px;
    }

    &__knowledge-expertises {
        > div {
            @include after-first(1) {
                margin-top: 16px;
            }
        }
    }
}
