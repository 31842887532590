.popover {
    position: absolute;
    z-index: 10;
    opacity: 0;
    transform: translateY(-5px);
    transition: all .2s cubic-bezier(0, 0, .3, 1);
    pointer-events: none;
    will-change: opacity;

    &.is-visible {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }

    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 13px 18px;
        align-items: stretch;
        justify-content: flex-start;
        min-width: 90px;
        background-color: $secondary-bg-color;
        transform: translateZ(0);
        backface-visibility: hidden;
        border-radius: 7px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .08);
    }
}
