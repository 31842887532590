.talent-job-posts-page {
    .job-post-details-container {
        width: 100%;
        max-width: 720px;

        &__main {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &__job-details,
        &__job-description,
        &__empty-jobs {
            @include friendlySectionAppear(.1s);
            background-color: #fff;
            border-radius: 7px;
            padding: 30px 30px;
        }

        &__empty-jobs {
            display: flex;
            align-items: center;
            padding: 30px 30px 60px;
        }

        &__job-details {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;

            @include mq($until: tiny) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &__job-details-logo,
        &__company-name-instead-of-logo {
            width: 70%;
            align-self: center;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;

            @include mq($until: tiny) {
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }
        }

        &__company-name-instead-of-logo {
            color: $secondary-font-color;
            font-weight: 800;
            text-align: center;
            font-size: 18px;
            overflow-wrap: break-word;
            min-width: 0;
        }

        &__job-details-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span:first-child {
                font-size: 14px;
                color: #1688f3;
            }
        }

        &__job-status {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        &__number-of-applicants {
            span:first-of-type {
                color: $secondary-font-color;
            }

            span:last-of-type {
                margin-left: 10px;
                cursor: pointer;
                color: $secondary-font-color;
                text-decoration: underline;
            }
        }

        &__qualifiers-values-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
        }

        &__qualifiers-values {
            display: flex;
            border-radius: 30px;
            background-color: #ecf2f8;
            color: #1688f3;
            margin: 0;
        }

        &__qualifiers-tag {
            border-radius: 30px;
            font-size: 12px;
            padding: 4px 12px;
            color: #1688f3;
            background-color: #ecf2f8;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__apply-button {
            width: 200px;

            &.applied{
                background-color: #51cb20;
            }
        }

        .status-dot {
            @include friendlySectionAppear;
            pointer-events: none;
            width: $attention-dot-size;
            height: $attention-dot-size;
            border-radius: $attention-dot-size;

            &__live {
                background-color: $live-dot-color;
            }

            &__declined {
                background-color: $declined-dot-color;
            }

            &__expired {
                background-color: $expired-dot-color;
            }

            &__pending {
                background-color: $pending-dot-color;
            }
        }
    }
}
