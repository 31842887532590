.experience-step {
    &__today-field {
        position: relative;
        align-self: flex-end;
        margin-left: 24px;

        .custom-checkbox__label {
            color: $success-color;
        }
    }

    .input-group {
        .custom-select {
            margin-left: 8px;

            @include after-first(2) {
                margin-left: 24px;
            }
        }
    }

    .wizard-items-list__experience-item:first-child {
        margin-top: -30px;
    }

    .key-activities-field {
        min-height: 82px;
        height: max-content;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        resize: vertical;
        line-height: 24px;
    }
}
