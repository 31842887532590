$hamburger-btn-width: 23px;

.hamburger-btn {
    align-self: center;
    justify-self: start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 21px;
    width: $hamburger-btn-width;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    @include mq($from: mobile) {
        display: none;
    }

    &__line {
        width: $hamburger-btn-width;
        height: 3px;
        background-color: #2c97fc;
        display: block;
        border-radius: 5px;
        transition: all $main-transition;
        will-change: transfrom;
    }

    &.is-active {
        .hamburger-btn__line {
            @include middle(3) {
                opacity: 0;
            }

            @include first-child {
                transform: translateY(9px) rotate(45deg);
            }

            @include last-child {
                transform: translateY(-9px) rotate(-45deg);
            }
        }
    }
}
