.create-job-post-modal {
    border-radius: 7px;
    overflow-y: initial;

    @include mq($until: mobile) {
        max-width: unset;
        border-radius: 0;
    }

    &__header {
        align-items: center;
    }

    &__sub-header {
        @include friendlySectionAppear(.1s);
        color: $secondary-font-color;
        text-align: center;

        span {
            font-weight: 800;
        }
    }

    &__body {
        padding-bottom: 20px;
        overflow-y: initial;
    }

    &__footer {
        button {
            width: 184px;
        }
    }

    form {
        display: grid;
        grid-template-columns: 285px 110px 25px;
        grid-gap: 30px;
        align-items: flex-end;
        justify-content: space-between;

        @include mq($until: mobile) {
            grid-template-columns: 1fr 110px 25px;
        }

        @include mq($until: small) {
            grid-template-columns: auto 1fr;
        }
    }

    &__left-form-side {
        @include mq($until: small) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }

    @include mq($until: mobile) {
        .modal__content {
            grid-template-rows: repeat(3, auto);
        }
    }
}
