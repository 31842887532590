.hh-pool-form {
    @include friendlySectionAppear;
    height: 100%;
    padding: 0 30px;

    @include mq($until: mobile) {
        margin-bottom: $mobile-user-area-height;
        padding: 0;
    }

    &.is-hidden {
        display: none;
    }

    &__content {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: $wizard-sidebar-width 1fr;
        padding: 40px 0 35px;

        @include mq($until: tablet) {
            display: block;
        }

        @include mq($until: mobile) {
            padding: 0;
            background-color: $secondary-bg-color;
        }
    }

    &__inner {
        width: 100%;
    }

    &__main {
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 30px 60px;
        min-height: 410px;

        @include mq($until: mobile) {
            padding-bottom: 30px;
        }
    }

    &__job-details-step .wizard-step__form-cell {
        max-width: 292px;
    }

    &__job-details-step {
        &__tooltip {
            margin-left: 10px;
        }
    }
}
