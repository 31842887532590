.payment-method-page {
    width: 100%;

    &__head {
        display: flex;

        gap: 30px;
        margin-bottom: 20px;
    }

    &__checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__edit-button {
        margin-left: auto;
    }
}
