.custom-option-with-logo {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    align-items: center;

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        $size: 30px;

        max-width: $size;
        max-height: $size;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;


        img {
            width: 100%;
        }
    }

    &__empty-image-icon {
        fill: $minor-font-color;
    }

    &__text {
        display: flex;
        flex-direction: column;

        &__label {
            font-size: $base-font-size;
        }

        &__inductry {
            font-size: 10px;
            color: $minor-font-color;
        }
    }

    &__checkbox {
        align-self: center;
    }
}
