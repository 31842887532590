.expansible-content {
    $experience-wizard-item-padding-mobile: 15px;
    $experience-wizard-item-padding: 40px;

    .wizard-items-list {
        &__item {
            padding: 0;

            @include after-first(1) {
                border-top: none;
            }
        }
    }

    .experience-wizard-item__experience-item {
        @include after-first(1) {
            padding-top: $experience-wizard-item-padding;
        }

        &__logo {
            padding-top: 0;
        }
    }

    &__section {
        position: relative;

        &:hover .section-edit-action {
            visibility: visible;
            opacity: 1;
        }

        &.has-action {
            padding-right: 50px;
        }

        &--condensed {
            .wizard-items-list {
                &__item {
                    @include after-first(1) {
                        margin-top: 30px;
                    }
                }

                &__item-title {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__section-heading {
        line-height: 30px;
        color: $primary-font-color;
        font-size: 24px;
        font-weight: 800;
        margin: 0 0 34px;
    }

    &__info--highlighted--portable {
        font-weight: 700;

        @include mq($until: tiny) {
            width: 100%;
        }
    }

    &__info--highlighted {
        font-weight: 700;
        flex-shrink: 0;

        @include mq($until: tiny) {
            width: 100%;
        }
    }

    &__pre-formatted {
        white-space: pre-wrap;
    }

    &__item-tag {
        color: $success-color;
        font-weight: 700;
    }

    &__item-row {
        display: flex;
        justify-content: space-between;

        &--shifted {
            margin-top: 8px;
        }

        &--condensed {
            justify-content: flex-start;

            p {
                line-height: 1.2;
            }

            > * {
                @include after-first(1) {
                    margin-left: 20px;
                }
            }
        }

        > .expansible-content__item-tag {
            flex-grow: 0;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }

    &__label-list {
        align-items: center;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        margin-right: -10px;

        &:hover .section-edit-action {
            visibility: visible;
            opacity: 1;
        }

        &.has-action {
            padding-right: 50px;
        }
    }

    &__label-tag {
        border-radius: 30px;
        background-color: $form-value-tag-bg;
        color: $secondary-font-color;
        margin: 0 10px 18px 0;
        font-size: 12px;
        padding: 4px 12px;
    }

    .assessment-results {
        margin: 0;

        &__list {
            max-width: 510px;
            margin: 0 auto;
        }
    }
}
