.create-hh-pool-modal {
    border-radius: 7px;
    max-width: 463px;
    overflow-y: initial;

    @include mq($until: mobile) {
        max-width: unset;
        border-radius: 0;
    }

    &__header {
        align-items: center;
    }

    &__body {
        padding-bottom: 20px;
        overflow-y: initial;
    }

    &__footer {
        button {
            width: 184px;
        }
    }

    form {
        max-width: 292px;
        margin: 0 auto;
    }

    @include mq($until: mobile) {
        .modal__content {
            grid-template-rows: repeat(3, auto);
        }
    }
}
