@mixin activeLabel() {
    top: -15px;
    font-size: 8px;
    line-height: 11px;
    color: $secondary-font-color;
}

.custom-select {
    margin-top: 35px;
    width: 100%;

    & & {
        &__field {
            position: relative;

            &.is-invalid {
                .custom-select__control {
                    border-color: $form-error-border-color;

                    &--is-focused,
                    &--menu-is-open {
                        .custom-select__border {
                            color: $form-error-border-color;
                        }

                        .custom-select__label {
                            color: $unsuccess-color;
                        }
                    }
                }

                .custom-select__value-container {
                    &--has-value {
                        ~ .custom-select__label {
                            color: $unsuccess-color;
                        }
                    }
                }
            }

            &.is-required {
                .custom-select__label {
                    &:not(:empty):after {
                        content: "*";
                        display: inline;
                    }
                }
            }

            &.hide-placeholder-on-focus {
                .custom-select__control {
                    &:not(.custom-select__control--is-focused) {
                        .custom-select__placeholder {
                            display: none;
                        }
                    }
                }
            }
        }

        &__label {
            color: $minor-font-color;
            position: absolute;
            pointer-events: none;
            left: 0;
            top: 0;
            transition: all .2s ease;
            will-change: opacity;
        }

        &__border {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            opacity: 0;
            pointer-events: none;
            color: $form-active-border-color;
            background-color: currentColor;
            transition: opacity .2s ease;
        }

        &__error {
            position: absolute;
            top: calc(100% + 2px);
            color: $unsuccess-color;
            font-size: 9px;
            line-height: 11px;
            animation: fade-in $main-transition;

            &:first-letter {
                text-transform: uppercase;
            }
        }

        &__placeholder {
            animation: fade-in $main-transition forwards;
        }

        &__control {
            background: none;
            border: none;
            box-shadow: none;
            border-radius: unset;
            width: 100%;
            min-height: 30px;
            border-bottom: 1px solid $form-border-color;

            &:hover {
                border-color: $form-border-color;
                cursor: pointer;
            }

            &--is-focused,
            &--menu-is-open {
                &,
                &:hover {
                    border-color: $form-active-border-color;
                }

                .custom-select__border {
                    opacity: 1;
                }

                .custom-select__label {
                    @include activeLabel;
                }
            }
        }

        &__value-container {
            padding: 0;

            &--has-value {
                ~ .custom-select__label {
                    @include activeLabel;
                }
            }
        }

        &__single-value {
            top: calc(50% - 3px);
            margin-left: 0;
        }

        &__multi-value__wrapper {
            border-radius: 30px;
            width: max-content;
            height: max-content;
            margin: 3px 8px 3px 2px;
            max-width: 680px;

            @include mq(200px) {
                max-width: 160px;
            }

            @include mq(240px) {
                max-width: 200px;
            }

            @include mq(280px) {
                max-width: 240px;
            }

            @include mq(320px) {
                max-width: 280px;
            }

            @include mq(360px) {
                max-width: 320px;
            }

            @include mq(400px) {
                max-width: 360px;
            }

            @include mq(440px) {
                max-width: 400px;
            }

            @include mq(480px) {
                max-width: 440px;
            }
        }

        &__multi-value {
            border-radius: 30px;
            background-color: $form-value-tag-bg;
            color: $secondary-font-color;
            transform: translateY(-3px);
            margin: 0;

            &__label {
                border-radius: 30px;
                font-size: 12px;
                padding: 4px 0 4px 12px;
                color: $secondary-font-color;
                background-color: $form-value-tag-bg;
            }

            &__remove {
                color: $secondary-font-color;
                padding-right: 8px;

                svg {
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    background-color: transparent;
                    color: $unsuccess-light-color;
                }
            }

            &.is--warning {
                border: 1px solid $pending-dot-color;
            }
        }

        &__input {
            transform: translateY(-3px);

            input {
                height: auto;
                transition: none;
            }
        }

        &__dropdown-indicator {
            font-size: 12px;
            color: $secondary-font-color;
            padding: 6px 2px 8px 8px;

            svg {
                transform: rotate(-.25turn);
            }
        }

        &__clear-indicator {
            padding: 4px;
        }

        &__menu {
            box-shadow: 0 0 6px rgba(0, 0, 0, .16);
            margin-top: 0;
            animation: fade-in $main-transition;
        }

        &__menu-list {
            @include customScrollbars;

            &--is-multi {
                .custom-select__option {
                    &:active {
                        background: transparent;

                        .custom-checkbox__checkbox {
                            &:after {
                                opacity: .75;
                            }
                        }
                    }

                    &--is-selected {
                        background: transparent;

                        &:hover {
                            .custom-checkbox__checkbox {
                                &:after {
                                    opacity: .5;
                                }
                            }
                        }
                    }

                    &--is-focused {
                        background: transparent;

                        .custom-checkbox__checkbox {
                            &:after {
                                opacity: .25;
                            }
                        }

                        &.custom-select__option--is-selected {
                            .custom-checkbox__checkbox {
                                &:after {
                                    opacity: .75;
                                }
                            }
                        }
                    }

                    .custom-checkbox__checkbox--is-checked {
                        &:after {
                            opacity: 1;
                        }
                    }

                    &--is-disabled {
                        opacity: .4;
                    }
                }
            }

            .powered-with-google {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                height: 20px;

                img {
                    height: 16px;
                    max-width: 100%;
                }
            }
        }

        &__option {
            cursor: pointer;
        }

        &__highlighted {
            font-style: normal;
            font-weight: 700;
        }
    }

    &--is-disabled {
        opacity: .4;

        .custom-select__control {
            pointer-events: auto;

            &:hover {
                cursor: not-allowed;
            }

            * {
                pointer-events: none;
            }
        }
    }
}
