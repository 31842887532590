.site-footer {
    grid-row: footer-area;
    background-color: #eff3fb;
    padding: 33px 31px 47px 38px;
    display: grid;
    grid-template-columns: auto 1fr 179px;
    grid-column-gap: 85px;

    @include mq($until: tablet) {
        grid-column-gap: 0;
    }

    @include mq($until: mobile) {
        align-items: center;
    }

    @include mq($until: small) {
        padding: 26px 16px 32px 16px;
    }

    &.is-hidden {
        display: none;
    }

    &__logo,
    &__nav,
    &__social-links {
        @include friendlySectionAppear;
    }

    &__logo {
        padding-top: 6px;

        @include mq($until: tablet) {
            padding-top: 0;
        }

        > a {
            display: flex;


            > svg {
                font-size: 44px;

                @include mq($until: mobile) {
                    font-size: 29px;
                }
            }
        }
    }

    &__nav {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 80px;
        justify-content: start;
        margin-left: 170px;
        padding-top: 20px;

        @include mq($until: 1600px) {
            margin-left: 42px;
        }

        @include mq($until: 1350px) {
            margin-left: 0;
        }

        @include mq($until: tablet) {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
            min-height: 102px;
            padding-top: 30px;
        }

        @include mq($until: tiny) {
            grid-column-gap: 0;
            justify-content: space-around;
        }
    }

    &__nav,
    .site-footer__link--light {
        font-size: 11px;
        line-height: 12px;
        font-weight: 400;
    }

    &__nav-col {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto;
        grid-template-rows: repeat(3, 14px);
        align-content: space-between;
        align-items: center;
        justify-items: start;
    }

    &__link {
        font-size: 13px;
        font-weight: 700;
        line-height: 14px;
    }

    &__social-links {
        display: flex;
        align-items: flex-start;
        grid-column: 3 / 4;

        a {
            font-size: 24px;
            display: inline-flex;
            flex-shrink: 0;
            color: #545454;
            transition: color .2s linear;

            @include after-first(1) {
                margin-left: 15px;
            }

            &:hover {
                color: #828282;
            }
        }
    }
}
