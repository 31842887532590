.account-settings-sidebar {
    &__content-wrapper {
        position: sticky;
        top: calc(var(--header-height) + 40px);

        > .delete-account-btn {
            width: 100%;
            margin-block-start: 30px;
        }

        .sidebar__inner {
            position: static;
            max-height: none;
        }
    }

    @include mq($until: tablet) {
        height: 100%;
        background-color: $secondary-bg-color;

        .account-settings-sidebar__content-wrapper {
            position: static;
            border-bottom: 1px solid $sidebar-nav-item-bg;
        }
    }

    @include mq($until: mobile) {
        margin-bottom: $mobile-user-area-height;
    }

    .sidebar-nav {
        &__item {
            &.is-active {
                .sidebar-nav__item-label {
                    color: $secondary-font-color;
                }
            }
        }

        &__item-arrow {
            display: none;
        }

        &__item-label {
            grid-column: 1 / -1;
            color: $primary-font-color;
        }
    }

    > .delete-account-btn {
        margin-inline-start: 30px;
        padding: 0;
        margin-block-start: 21px;
        font-size: 16px;
        height: auto;
    }
}
