.edit-file-modal {
    max-width: 720px;

    .modal__header {
        align-items: center;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .modal__close {
        top: 30px;
        right: 30px;
    }

    .file-dropzone-upload {
        margin: 0;
    }

    &__body {
        padding: 0 30px 33px;
    }
}
