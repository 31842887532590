.delete-account-btn {
    &--text {
        background-color: transparent;
        color: $unsuccess-color;
        box-shadow: none;
        text-transform: none;
        font-size: 14px;

        &:hover,
        &:active {
            background-color: transparent;
            color: $unsuccess-color;
        }
    }
}
