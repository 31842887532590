.header-action-btn {
    @include friendlySectionAppear;
    font-size: 33px;
    color: $button-primary-bg;
    display: inline-flex;
    padding: 6px 5px 0 0;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &__icon {
        transform: scale3d(1, 1, 1);
        transition: transform $main-transition;
        flex-shrink: 0;
        overflow: visible;
    }

    &__badge {
        @include friendlySectionAppear;
        position: absolute;
        padding: 4px;
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        min-width: 19px;
        height: 19px;
        line-height: 11px;
        border-radius: 10px;
        font-size: 10px;
        font-weight: 700;
        color: $button-primary-color;
        background-color: $success-color;
    }
}
