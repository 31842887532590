.bubbles {
    @include stretch;
    position: fixed;
    z-index: -1;
    pointer-events: none;

    &--type-simple {
        .bubbles__top-right {
            width: 233px;
            height: 446px;

            @include mq($until: mobile) {
                width: 25vw;
            }
        }

        .bubbles__bottom-left {
            width: 218px;
            height: 414px;

            @include mq($until: mobile) {
                width: 25vw;
            }
        }
    }

    &--type-advanced {
        .bubbles__top-right {
            width: 448px;
            height: 563px;

            @include mq($until: mobile) {
                width: 25vw;
                height: 500px;
            }
        }

        .bubbles__bottom-left {
            width: 164px;
            height: 716px;

            @include mq($until: mobile) {
                width: 20vw;
                height: 400px;
            }
        }
    }

    &--type-additional {
        background-color: $secondary-bg-color;


        .bubbles__top-right {
            width: 364px;
            height: 238px;

            @include mq($until: mobile) {
                width: 200px;
                height: 270px;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .bubbles__bottom-left {
            width: 100%;
            height: 576px;

            @include mq($until: mobile) {
                width: 100%;
                height: 200px;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__top-right,
    &__bottom-left {
        position: absolute;
        overflow: hidden;

        svg {
            position: absolute;
            width: 786px;
            height: 791px;
        }
    }

    &__top-right {
        right: 0;
        top: 0;

        svg {
            bottom: 0;
            left: 0;
        }
    }

    &__bottom-left {
        bottom: 0;
        left: 0;

        svg {
            right: 0;
            top: 0;
        }
    }
}
