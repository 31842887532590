.pricing-questions {
    padding: 100px 30px;
    box-shadow: 0 5px 30px -10px rgba(18, 63, 82, .3);

    &__inner {
        height: 100%;
        padding: 24px 50px 100px;
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        background-color: #fff;
    }

    &__icon {
        display: block;
        margin: 0 auto;
    }

    &__title {
        text-align: center;

    }

    &__description {
        text-align: center;
        margin-bottom: 60px;

        & > a {
            color: $secondary-font-color;
        }

        @include mq($until: mobile) {
            font-size: 14px;
        }
    }

    &__questions-wrapper {
        display: flex;
        justify-content: space-between;

        @include mq($until: mobile) {
            flex-direction: column;
            gap: 30px;
        }
    }

    &__question {
        max-width: 324px;
        width: 100%;
        padding: 5px;

        @include mq($until: mobile) {
            padding: 0;
            max-width: unset;
        }
    }

    &__question-title {
        margin-top: 0;
        color: #000;
        font-size: 18px;
        margin-bottom: 20px;

        @include mq($until: mobile) {
            font-size: 14px;
        }
    }

    &__answer {
        font-size: 18px;
        margin: 0;

        @include mq($until: mobile) {
            min-height: 150px;
            font-size: 12px;
        }
    }
}
