.compare-talents-card {
    pointer-events: auto;
    padding: 23px 30px 30px;
    border-radius: 7px;
    background-color: #fff;
    min-height: 458px;

    @include mq($until: desktop) {
        background-color: $primary-bg-color;
        margin: 0 19px;
    }

    @include mq($until: mobile) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include after-first(1) {
        margin-top: 30px;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            line-height: 32px;
            font-size: 24px;
            text-transform: none;
            margin: 0;
        }
    }

    &__content {
        margin-top: 27px;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 50px;
        align-items: center;

        @include mq($until: desktop) {
            display: block;
        }

        > div {
            min-width: 0;
            height: 100%;

            @include first-child {
                @include friendlySectionAppear(0s, linear);
            }

            @include last-child {
                width: 430px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include mq($until: desktop) {
                    margin: 37px auto;
                    width: unset;

                    .talent-comparison-chart {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &__overall-info {
        display: flex;
    }

    &__overall-info-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 0;

        @include first-child {
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            padding-bottom: 20px;
            margin-right: 40px;

            @include mq($until: mobile) {
                margin-right: 15px;
            }
        }

        @include last-child {
            @include mq($until: mobile) {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-areas:
                    "n m"
                    "l m";
                flex: 1;
            }
        }
    }

    &__user-image {
        width: $compare-modal-avatar-size;
        height: $compare-modal-avatar-size;
        position: relative;
        flex-shrink: 0;

        .user-avatar__image {
            font-size: $compare-modal-avatar-size;
        }

        @include mq($until: mobile) {
            width: 54px;
            height: 54px;

            .user-avatar__image {
                font-size: 54px;
            }
        }
    }

    &__fullname {
        font-size: 32px;
        font-weight: 800;
        line-height: 43px;
        color: $secondary-font-color;
        margin-top: 13px;
        max-width: 100%;
        overflow-wrap: break-word;

        @include mq($until: mobile) {
            font-size: 14px;
            grid-area: n;
            margin: 0;
            line-height: unset;
            align-self: center;
        }
    }

    &__location {
        line-height: 32px;
        font-size: 24px;
        margin-top: 8px;
        max-width: 100%;
        overflow-wrap: break-word;

        @include mq($until: mobile) {
            font-size: 12px;
            grid-area: l;
            margin: 0;
            line-height: unset;
        }
    }

    &__match-score {
        margin-top: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 23px;
        font-weight: 700;

        @include mq($until: mobile) {
            font-size: 12px;
            grid-area: m;
            margin: 0;
            align-items: flex-end;
        }

        span {
            font-size: 45px;
            line-height: 45px;
            font-weight: 800;
            color: $success-color;

            @include mq($until: mobile) {
                font-size: 18px;
                line-height: unset;
            }
        }
    }

    &__preferences {
        margin-top: 52px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px 20px;

        @include mq($until: mobile) {
            margin-top: 15px;
            font-size: 12px;
            grid-gap: 8px 10px;
        }
    }

    .talent-preferences-info-field {
        display: flex;
        line-height: 21px;
        min-width: 0;

        & + & {
            margin-top: 19px;
        }

        > div {
            @include first-child {
                flex-shrink: 0;
                width: 92px;

                @include mq($until: mobile) {
                    width: unset;
                }
            }

            @include last-child {
                margin-left: 26px;
                font-weight: 800;
                color: $secondary-font-color;
                overflow-wrap: break-word;
                min-width: 0;

                @include mq($until: mobile) {
                    margin-left: 5px;
                }
            }
        }
    }

    .talent-comparison-chart{
        margin-bottom: 0;

        &__legend {
            margin-top: 0;
            margin-bottom: 10px;
        }

        &__label {
            svg {
                display: none;
            }

            &:hover {
                cursor: default;
            }
        }
    }

    .heart-btn {
        @include friendlySectionAppear(.2s);
    }
}
