.register-type-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
        margin-top: 60px;
        flex-shrink: 0;
        display: flex;
        opacity: 0;
        animation: fade-in .3s linear forwards;

        @include mq($until: mobile) {
            margin-top: 21px;
            align-self: flex-start;
            margin-left: 20px;
        }

        svg {
            max-height: 98px;
            min-height: 28px;
            width: auto;
            max-width: 100%;
            font-size: 12vh;

            @include mq($until: mobile) {
                font-size: 28px;
            }
        }
    }

    > h1 {
        font-size: 44px;
        line-height: 55px;
        margin: 46px 0 0;
        text-transform: uppercase;
        font-weight: 900;
        opacity: 0;
        animation: fade-in .3s linear forwards;

        @include mq($until: mobile) {
            font-size: 17px;
            margin-top: 0;
            font-weight: 800;
            line-height: 21px;
        }
    }

    .register-type-meta {
        line-height: 20px;
        margin-top: 19px;
        opacity: 0;
        animation: fade-in .3s linear forwards;

        @include mq($until: mobile) {
            margin-top: 14px;
        }
    }

    .register-type-options {
        margin-top: 49px;
        display: flex;
        align-items: center;

        @include mq($until: mobile) {
            flex-direction: column;
            margin-top: 30px;
            padding-bottom: 50px;
        }
    }

    .register-type-option {
        background-color: $secondary-bg-color;
        border-radius: 7px;
        width: $register-type-option-size;
        height: $register-type-option-size;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards;

        @include mq($until: mobile) {
            width: 50vw;
            height: 50vw;
            min-width: 210px;
            min-height: 210px;
            max-width: $register-type-option-size;
            max-height: $register-type-option-size;
        }

        @include after-first(1) {
            animation-delay: .07s;
            margin-left: 104px;

            @include mq($until: mobile) {
                margin-top: 30px;
                margin-left: 0;
            }
        }

        @include onMouseDevices {
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }

        &:before {
            @include stretch;
            content: "";
            box-shadow: 0 0 20px rgba(0, 0, 0, .08);
            z-index: -1;
            opacity: 0;
            transition: opacity $main-transition;
        }

        &__title {
            font-size: 18px;
            font-weight: 800;
            line-height: 32px;
            margin: 0 0 18px;
            text-transform: uppercase;
        }

        &__image {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: center;

            svg {
                height: 100%;
                width: auto;
                flex: 1;
            }
        }

        &__meta {
            text-align: center;
            margin-top: 22px;
            line-height: 20px;
        }
    }
}
