.payment-page {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mq($until: tablet) {
        flex-direction: column-reverse;
        gap: 30px;
    }

    @include mq($until: mobile) {
        padding-bottom: 90px;
    }
}
