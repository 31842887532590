.year-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &__current-year {
        margin: 0;
    }

    &__prev-button {
        transform: rotate(-90deg);
    }

    &__next-button {
        transform: rotate(90deg);
    }
}
