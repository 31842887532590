.expansion-panel {
    border-bottom: 1px solid $loader-backdrop-bg;

    &.expanded {
        border-bottom: 1px solid transparent;
    }

    &:last-child {
        border-bottom: none;
    }

    &__content {
        $shift-height: 6px;
        transition: $main-transition;

        padding: $shift-height 0 0;
        margin-bottom: 20px;

        &.collapsing {
            transform: translateY(-#{$shift-height});
            opacity: .1;
        }

        &.collapsed {
            transform: translateY(-#{$shift-height});
            opacity: .1;
        }

        &.expanding .rc-slider-tooltip {
            display: none;
        }
    }

    &__collapse {
        max-width: 100%;
    }

    &__header {
        width: 100%;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color .3s, opacity .3s;
        color: $secondary-font-color;

        &:hover {
            background-color: $loader-backdrop-bg;
        }

        //Safari do not support :focus-visible
        &:focus-visible {
            background-color: $loader-backdrop-bg;
        }

        &:disabled {
            opacity: .5;
        }
    }

    &__header-content {
        font-size: 16px;
        font-weight: 800;
        position: relative;
        flex: 1;
        text-align: left;
    }

    &__indicator {
        display: flex;
        align-items: center;

        svg {
            flex-shrink: 0;
        }
    }
}

.chevron-animated {
    display: block;

    &__container {
        transition: transform .3s;
        transform: translateY(13px);
    }

    &__line1,
    &__line2 {
        stroke-width: 12;
        stroke-linecap: round;
        transition: transform .3s, stroke .3s;
        transform-origin: 50px 50px;
        stroke: currentColor;
    }

    &__line1 {
        transform: rotate(45deg);
    }

    &__line2 {
        transform: rotate(-45deg);
    }
}

.expanded {
    .chevron-animated {
        &__container {
            transform: translateY(-6px);
        }

        &__line1,
        &__line2 {
            transform: rotate(0deg);
        }
    }
}
