.assessment-results {
    &__title {
        font-size: 24px;
        font-weight: 800;
        color: $primary-font-color;
        margin: 0 0 30px;
    }

    &__list {
        margin: 30px 0 0;
    }

    &__list-item {
        margin: 10px 0;
    }

    &__item {
        display: flex;
        align-items: center;

        > * {
            @include after-first(1) {
                margin-left: 30px;
            }
        }
    }

    &__progress {
        height: 15px;
        flex-basis: 220px;
        flex-shrink: 0;

        @include mq($until: mobile) {
            flex-basis: 130px;
        }
    }

    &__item-label {
        font-size: 14px;
        font-weight: 400;
        flex: 1 1;
    }

    &__item-tooltip {
        flex-shrink: 0;
        flex-grow: 0;
    }
}
