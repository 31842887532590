.img-fade-in {
    --animation-duration: 300ms;
    --animation-delay: 0ms;

    opacity: 0;
    position: relative;
    transition: opacity $main-transition;
    transition-duration: var(--animation-duration);
    transition-delay: var(--animation-delay);

    &.is-loaded {
        opacity: 1;

        &.instant-load {
            @include friendlySectionAppear($delay: 0s, $cubic-bezier-fn: $main-cubic-bezier-fn, $duration: .6s);

            .img-fade-in__placeholder {
                animation: none;
            }
        }
    }

    &.is-failed {
        display: none;

        + .img-fade-in__failed-icon {
            @include friendlySectionAppear;
            color: #efefef;
            flex-shrink: 0;
            border-radius: 0;
        }
    }

    &__placeholder {
        @include stretch;
        @include friendlySectionAppear($delay: 0s, $cubic-bezier-fn: $main-cubic-bezier-fn, $duration: .2s);
        pointer-events: none;
        background: #efefef;
    }

}
