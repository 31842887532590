.talent-dashboard {
    @include friendlySectionAppear;
    height: 100%;
    padding: 0 30px;

    .experience-wizard-item__experience-item__logo {
        @include mq($until: mobile) {
            margin-bottom: 15px;
        }
    }

    .section-edit-action {
        position: absolute;
        top: 25px;
        right: 40px;
        visibility: hidden;
        opacity: 0;
        transition: opacity $main-transition;

        @include mq($until: tiny) {
            right: 10px;
        }

        @media (hover: none) {
            visibility: visible;
            opacity: 1;
        }

        &__button,
        &__link {
            $size: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            border-radius: 50%;
            width: $size;
            height: $size;
            background-color: $button-primary-bg;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: $button-primary-color;
                }
            }
        }
    }

    @include mq($until: tablet) {
        &:not(.talent-setup) {
            .profile-sidebar__heading {
                grid-area: h;
            }

            .profile-sidebar__outside-element {
                grid-area: o;
            }

            .profile-sidebar__children {
                grid-area: c;
            }
        }
    }

    @include mq($until: mobile) {
        &:not(.talent-setup) {
            padding: 0;
            margin-bottom: $mobile-user-area-height;

            .profile-sidebar__main-content {
                display: grid;
                grid-template-areas:
                    "h"
                    "c"
                    "o";

                &_inverse {
                    grid-template-areas:
                        "h"
                        "o"
                        "c";
                }
            }
        }
    }

    @include mq($from: mobile, $until: tablet) {
        &:not(.talent-setup) {
            .profile-sidebar__main-content {
                display: grid;
                padding-bottom: 20px;
                grid-template-areas:
                    "h c c"
                    "h o o";

                &_inverse {
                    grid-template-areas:
                        "h o o"
                        "h c c";
                }

                .profile-sidebar__heading {
                    margin-bottom: 26px;
                }

                .profile-sidebar__outside-element {
                    padding: 26px 10px;
                }

                .profile-sidebar__children {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    & > * {
                        flex: 1;
                        margin: 0;
                        border: none;
                        text-align: start;
                    }
                }
            }
        }
    }

    &__content {
        display: grid;
        grid-column-gap: 45px;
        grid-template-columns: $profile-sidebar-width 1fr;
        padding: 40px 0 35px;

        @include mq($until: mobile) {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    &__main {
        width: 100%;

        @include mq($until: tablet) {
            margin-top: 30px;
        }
    }

    .traits-and-competencies,
    .values-and-motivation {
        @include mq($from: 900px, $until: 1320px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .assessment-results {
            padding: 0;
        }
    }

    &__heading {
        margin-bottom: 30px;
        font-size: 16px;
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 28px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__info {
        padding: 30px 30px 10px;
        background-color: $secondary-bg-color;
        border-radius: 7px;

        @include mq($until: mobile) {
            padding: 30px 8px 10px;
        }

        @include after-first(1) {
            margin-top: 30px;
        }

        &.talent-top-visitors {
            background-color: transparent;
        }
    }

    &__info-title {
        margin: 0 0 20px;
        font-size: 20px;
        font-weight: 800;
        color: $secondary-font-color;
        text-align: center;
        text-transform: uppercase;
    }

    .assessment-results {
        @include friendlySectionAppear;
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 30px 30px 40px;
        margin: 30px 0;

        @include mq($until: tablet) {
            margin: 0;
        }

        @include mq($until: tiny) {
            padding: 30px 10px 40px;

            &__item-label {
                font-size: 12px;
                margin-left: 10px;
            }

            &__item-tooltip {
                margin-left: 10px;
            }
        }
    }

    &__job-preferences {
        position: relative;
        margin-top: 30px;

        &:hover .section-edit-action {
            visibility: visible;
            opacity: 1;
        }
    }

    &__cv-summary {
        position: relative;
        margin-top: 30px;

        &:hover .section-edit-action {
            visibility: visible;
            opacity: 1;
        }
    }

    .column-edit-action {
        position: static;
    }

    .expanded-edit-action {
        top: 0;
        right: 10px;

        @include mq($until: mobile) {
            right: 0;
        }
    }

    @include mq($from: mobile, $until: tablet) {
        .talent-sidebar__top-quality:first-child {
            border: none;
        }
    }
}
