.confirm-payment {
    width: 40%;
    background-color: #fff;
    padding: 50px 30px 30px;
    border-radius: 10px;
    
    @include mq($until: tablet) {
        width: 100%;
    }

    &__plan {
        &-info {
            display: flex;
            justify-content: space-between;
            font-weight: 800;
            font-size: 22px;
            margin-bottom: 5px;

            &__name, &__price {
                margin-bottom: 0;
            }
        }

        &-description {
            font-weight: 600;
            font-size: 18px;
        }

        &-benefits {
            color: $secondary-font-color;
            margin: 0;
        }

        &-credits {
            color: #6a6a6a;
            margin: 0;
        }
    }

    &__advantage {
        margin-bottom: 40px;
        font-weight: 600;
    }

    &__total-charge {
        display: flex;
        justify-content: space-between;
        margin: 50px 0;
        font-size: 18px;
        font-weight: 700;
    }

    &__button {
        width: 100%;
    }

    &__subscription-info {
        margin-top: 30px;
        font-weight: 600;
        color: #6a6a6a;
    }

    &__subscription-currency-info {
        margin-bottom: 0;
    }
}
