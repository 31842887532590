.job-post-form-sidebar {
    .sidebar__head {
        padding: 30px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        line-height: 32px;
        min-height: 32px;
    }

    &__details-wrapper {
        padding: 10px 30px;
    }

    &__job-title {
        word-break: break-word;
        margin-bottom: 0px;
    }

    &__job-details-label {
        font-size: 8px;
        line-height: 11px;
        color: #1688f3;
    }

    &__job-details {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 180px;
        font-size: 16px;
        font-weight: 400;
        color: $minor-font-color;
    }

    .sidebar-nav {
        &__item-label {
            grid-column: 1 / 3;
            color: $primary-font-color;

            span {
                cursor: pointer;
            }
        }
    }
}
