@keyframes reveal-mobile-links {
    0% {
        transform: translate3d(200px, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}


.mobile-nav {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $secondary-bg-color;
    z-index: 100;
    transition: opacity .1s linear;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    top: -70px;
    padding: 0 0 calc(#{$header-height} + 70px);
    transform: translateY(calc(#{$header-height} + 70px));

    @include mq($from: mobile) {
        display: none;
    }

    &.is-visible {
        opacity: 1;
        pointer-events: auto;

        a {
            animation: reveal-mobile-links .4s cubic-bezier(.34, 1.56, .64, 1) .1s forwards;
        }
    }

    &__inner {
        padding: 30px;
        width: 100%;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mq($until: small) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include mq($until: tiny) {
            padding-left: 15px;
            padding-right: 15px;
        }

        a {
            transform: translate3d(250px, 0, 0);
        }
    }

    .site-header__link {
        $linksCount: 5;
        order: 2;
        font-size: 18px;
        line-height: 20px;

        @include after-first(1) {
            margin-top: 40px;
        }

        @for $i from 2 through $linksCount {
            &:nth-child(#{$i}) {
                animation-delay: ($i - 1) * .07s + .1s;
            }
        }

        &:hover,
        &.is-active {
            color: $primary-font-color;
        }
    }
}
