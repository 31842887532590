.choose-plan {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;

    &__inner {
        background-color: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 40px 20px;
        gap: 40px;
    }

    &__title {
        text-align: center;
        margin: 0;
        color: $primary-font-color;
    }

    &__sub-title {
        text-align: center;
        margin: 5px auto 20px;
        font-weight: 600;
        width: 85%;
    }

    &__content {
        font-size: 18px;
        text-align: left;
        margin: 0;
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }

    &__button {
        text-align: center;
        padding: 0 60px;

    }
}
