.sidebar-nav {
    flex-shrink: 0;

    &__item {
        position: relative;
        height: 60px;
        display: grid;
        align-items: center;
        flex-shrink: 0;
        grid-template-columns: 35px 1fr 22px;
        padding: 0 30px;
        overflow: hidden;
        border-top: 1px solid $sidebar-nav-item-bg;
        text-transform: capitalize;

        &:before {
            @include stretch;
            content: "";
            opacity: 0;
            background-color: $sidebar-nav-item-bg;
            transition: opacity .4s cubic-bezier(0, 0, .3, 1);
            z-index: -1;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            opacity: 0;
            transform: translateX(-100%);
            background-color: $secondary-font-color;
            transition: opacity .3s linear, transform .3s cubic-bezier(.34, 1.56, .64, 1.1);
        }

        &.is-hidden {
            display: none;
        }

        &.is-completed,
        &.is-active {
            .sidebar-nav__item-label {
                color: $primary-font-color;
            }
        }

        &.is-active {
            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 1;
                transform: translateX(0);
            }

            .sidebar-nav__item-label {
                font-weight: 800;
            }

            ~ .sidebar-nav__item .sidebar-nav__item-icon {
                display: none;
            }
        }

        &.is-completed:not(.is-active) {
            .sidebar-nav__item-icon {
                font-size: 20px;
                color: $success-color;
            }
        }
    }

    &__item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        font-size: 14px;
        color: $secondary-font-color;

        svg {
            opacity: 0;
            animation: fade-in .3s linear forwards;
        }
    }

    &__item-status-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        width: $attention-dot-size;
        height: $attention-dot-size;
        border-radius: $attention-dot-size;

        &--live {
            background-color: $live-dot-color;
        }

        &--declined {
            background-color: $declined-dot-color;
        }

        &--expired {
            background-color: $expired-dot-color;
        }

        &--pending {
            background-color: $pending-dot-color;
        }
    }

    &__item-label {
        grid-column: 2 / 3;
        color: $minor-font-color;
        padding-right: 20px;

        a {
            color: inherit;
        }
    }

    &__item-arrow {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $secondary-font-color;

        svg {
            transform: rotate(.5turn);
        }
    }
}
