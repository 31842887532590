.page-not-found {
    text-align: center;

    &__header {
        padding: 140px 0 20px;
    }

    &__title {
        font-size: 40px;
        font-weight: 900;
        color: $primary-font-color;
        margin: 11px 0 0;

        @include mq($until: mobile) {
            font-size: 32px;
        }
    }

    &__subtitle {
        font-size: 29px;
        font-weight: 800;
        color: $secondary-font-color;
    }

    &__footer {
        font-size: 448px;
        line-height: 0;

        @include mq($until: mobile) {
            font-size: 224px;
        }

        svg {
            max-width: 100vw;
        }
    }
}
