.user-avatar {
    &__image {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        height: 1em;
        border-radius: 1em;

        > img,
        > svg {
            border: 2px solid $secondary-bg-color;
            background-color: $secondary-bg-color;
            border-radius: 1em;
        }

        > img {
            @include imgCover;
            display: block;
        }

        > svg {
            height: 100%;
            width: 100%;
        }

        .img-fade-in__placeholder {
            border-radius: 1em;
            top: 1px;
            left: 1px;
            bottom: 1px;
            right: 1px;
        }
    }

    &__badge-container {
        position: relative;

        .talent-rank-badge {
            @include absoluteCenter;
            top: auto;
            bottom: -20px;

            &--small {
                bottom: -8px;
            }
        }
    }
}
