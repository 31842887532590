@keyframes outline-highlight {
    0% {
        outline-width: 0px;
    }

    100% {
        outline-width: 5px;
    }
}

.onboarding {
    position: relative;

    &__wrapper {
        border-radius: 100%;
        outline: $button-primary-bg solid 0;

        &--highlight {
            animation: outline-highlight .3s linear forwards .8s;
        }
    }

    &__dialog {
        position: absolute;
        bottom: calc(100% + 12px);
        right: -16px;
        width: 380px;
        background-color: $button-primary-bg;
        color: $button-primary-color;
        padding: 24px;
        border-radius: 7px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .16);
        opacity: 0;
        animation: fade-in .3s linear forwards .8s;

        @include mq($until: tiny) {
            width: 240px;
        }

        h3 {
            color: $button-primary-color;
            margin: 0 0 8px;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 4px;
            font-weight: 700;
        }

        &-actions {
            margin-top: 16px;
            text-align: right;
        }

        .btn--secondary {
            color: $button-primary-color;
            border-color: $button-primary-color;
        }
    }
}
