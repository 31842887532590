input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="url"],
input[type="tel"],
textarea {
    font-family: inherit;
    font-weight: 400;
    font-size: $base-font-size;
    padding: 0;
    height: 100%;
    width: 100%;
    color: $primary-font-color;
    border: 1px solid $form-border-color;
    border-radius: 0;
    background-color: $secondary-bg-color;
    touch-action: manipulation;
    background-image: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all $main-transition;
}

select {
    display: block;
    font-size: $base-font-size;
    font-family: inherit;
    color: $primary-font-color;
    line-height: 1.3;
    padding: .5em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    margin: 0;
    border: 1px solid $form-border-color;
    box-shadow: none;
    border-radius: 0;
    appearance: none;
    background-color: $secondary-bg-color;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%231688f3%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right .7em top 50%;
    background-size: .5em auto;
    transition: all $main-transition;

    &:hover {
        border-color: $form-active-border-color;
    }

    &:focus {
        border-color: $form-active-border-color;
        box-shadow: none;
        color: $primary-font-color;
        outline: none;
    }

    option {
        font-weight: 400;
    }

    &:disabled,
    &[aria-disabled="true"] {
        color: $primary-font-color;
        opacity: .4;
        cursor: not-allowed;
    }
}

input[type="range"] {
    appearance: slider-horizontal;
}

.error-message {
    color: $unsuccess-color;
    font-size: 9px;
    line-height: 11px;
    margin-top: 2px;
    animation: fade-in $main-transition;

    &:first-letter {
        text-transform: uppercase;
    }

    &--absolute {
        position: absolute;
        top: calc(100% + 2px);
    }

    &--info {
        color: $secondary-font-color;
    }

    &--warning {
        color: $pending-dot-color;
    }
}
