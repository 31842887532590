.chat-contacts {
    &__list {
        padding-top: 15px;
    }

    &__entry {
        @include friendlySectionAppear(.1s);
        min-height: 40px;
        display: grid;
        align-items: center;
        grid-template-columns: $chat-avatar-size 1fr auto;
        grid-column-gap: 25px;
        width: 100%;

        @include after-first(1) {
            margin-top: 30px;
        }

        &:hover {
            cursor: pointer;
        }

        &.is-unread {
            .chat-contacts__entry-name {
                font-weight: 900;
                font-size: 15px;
            }

            .chat-contacts__entry-role {
                font-weight: 700;
            }

            .chat-contacts__entry-last-seen {
                > div {
                    font-weight: 800;
                    color: $secondary-font-color;
                }

                &--active-now {
                    > div {
                        color: $success-color;
                    }
                }

                time {
                    font-weight: 700;
                    color: $primary-font-color;
                }
            }
        }
    }

    &__entry-image {
        width: $chat-avatar-size;
        height: $chat-avatar-size;
        position: relative;
        flex-shrink: 0;

        .user-avatar__image {
            font-size: $chat-avatar-size;
        }
    }

    &__entry-info {
        min-width: 0;
    }

    &__entry-name {
        color: $secondary-font-color;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        overflow-wrap: break-word;
    }

    &__entry-role {
        font-size: 12px;
        margin-top: 5px;
        line-height: 16px;
        overflow-wrap: break-word;
    }

    &__entry-last-seen {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-size: 12px;
        line-height: 16px;

        > div {
            color: rgba($secondary-font-color, .5);
            font-weight: 700;
        }

        time {
            margin-top: 6px;
            color: rgba($primary-font-color, .5);
        }

        &--active-now {
            align-self: baseline;

            > div {
                color: rgba($success-color, .75);
            }
        }
    }
}
