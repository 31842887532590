.drawer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: -70px;
    transform: translateY(70px);
    padding-bottom: 70px;

    &__container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 70px;
        z-index: 1;
        pointer-events: none;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;

        @include mq($until: mobile) {
            height: Calc(100% + 60px); // fix mobile chrome floating address bar on scroll
        }
    }

    &__inner {
        position: relative;
        z-index: 10;
        max-width: $drawer-width;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        transform: translate3d(100%, 0, 0);
        background-color: $drawer-bg;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
        transition: transform .4s cubic-bezier(.22, 1, .36, 1);

        &.is-open {
            transform: translate3d(0, 0, 0);
            pointer-events: auto;
        }

        @include mq($until: tiny) {
            max-width: unset;
        }
    }

    &__content {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }

    &__close {
        display: inline-flex;
        padding: 0;
        color: $primary-font-color;
        -webkit-tap-highlight-color: transparent;

        &:hover,
        &:active {
            color: $primary-font-color;
        }

        svg {
            font-size: 20px;
        }
    }

    &__header {
        padding: 20px;
        display: flex;
        align-items: center;
        line-height: 30px;
        color: $secondary-font-color;
        font-size: 24px;
        font-weight: 900;

        > .drawer__close {
            position: static;
            margin-left: auto;
        }
    }

    &__body {
        overflow: hidden;
        height: 100%;

        @include mq($until: mobile) {
            height: calc(100% - 60px); // fix mobile chrome floating address bar on scroll
        }
    }
}
