.change-job-post-status-modal {
    border-radius: 7px;
    max-width: 390px;

    @include mq($until: mobile) {
        max-width: unset;
        border-radius: 0;
    }

    &__header {
        align-items: center;
    }

    &__sub-header {
        @include friendlySectionAppear(.1s);
        color: $secondary-font-color;
        text-align: center;

        span {
            font-weight: 800;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        padding-bottom: 20px;
    }

    &__job-status {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__footer {
        button {
            width: 184px;
        }
    }

    @include mq($until: mobile) {
        .modal__content {
            grid-template-rows: repeat(3, auto);
        }
    }

    .status-dot {
        @include friendlySectionAppear;
        pointer-events: none;
        width: $attention-dot-size;
        height: $attention-dot-size;
        border-radius: $attention-dot-size;

        &__live {
            background-color: $live-dot-color;
        }

        &__declined {
            background-color: $declined-dot-color;
        }

        &__expired {
            background-color: $expired-dot-color;
        }

        &__pending {
            background-color: $pending-dot-color;
        }
    }
}
