.talent-cards-result {
    width: 100%;
    max-width: 720px;

    @include mq($until: desktop) {
        margin: auto;
    }

    &__count {
        @include friendlySectionAppear(.1s);

        span {
            font-weight: 700;
            color: $secondary-font-color;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__action-item {
        margin-top: 20px;
        margin-right: 30px;
        flex-shrink: 0;
        flex-grow: 0;

        @include mq($until: small) {
            flex-basis: 100%;
        }

        @include last(1) {
            @include mq($from: mobile) {
                margin-right: 0;
            }
        }

        &--select {
            flex-basis: 110px;
            max-width: 258px;
            flex-grow: 1;
        }

        &--button {
            flex-basis: auto;
        }
    }

    &__list {
        margin-top: 20px;
    }

    .paginator {
        margin-top: 30px;
    }
}
