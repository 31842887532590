.common-modals {
    border-radius: 7px;

    @include mq($until: mobile) {
        border-radius: 0;
    }

    &.without-footer {
        .common-modals__body {
            padding-bottom: 30px;
            min-height: 248px;
        }

        .common-modals__title {
            margin-top: 0;
        }

        &.confirm-modal,
        &.info-modal {
            .common-modals__body {
                justify-content: center;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 136px;

        @include mq($until: mobile) {
            align-self: center;
        }
    }

    &__title {
        margin: 10px 0 0;
        font-size: 24px;
        line-height: 30px;
        font-weight: 800;
        color: $primary-font-color;
        text-align: center;
    }

    &__content {
        margin-top: 12px;
        text-align: center;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal__close {
        top: 30px;
        right: 30px;
    }

    .modal__content {
        @include mq($until: mobile) {
            grid-template-rows: auto;
        }
    }

    .btn {
        width: 184px;

        + .btn {
            margin-left: 15px;

            @include mq($until: mobile) {
                margin-left: 0;
                margin-top: 10px;
            }
        }

        @include mq($until: mobile) {
            width: 137px;
        }
    }

    &__actions {
        display: flex;
        margin-top: 38px;

        @include mq($until: mobile) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    &.error-modal {
        .common-modals {
            &__title {
                color: $attention-color;
                margin-top: 10px;
            }

            &__content {
                font-weight: 800;
                line-height: 24px;
                max-width: 400px;
                padding-bottom: 40px;
            }
        }
    }
}
