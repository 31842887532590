.heart-btn {
    font-size: 20px;
    color: $attention-color;
    display: inline-flex;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    svg {
        transform: scale3d(1, 1, 1);
        transition: transform $main-transition;
        flex-shrink: 0;
        overflow: visible;

        &.is-filled {
            transform: scale3d(.9, .9, .9);
        }
    }
}
