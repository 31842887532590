.talent-details__expansion-panel {
    .expansion-panel {
        &.expanded {
            .expansion-panel__header {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &__header {
            border-radius: 8px / 5px;
            min-height: 63px;
            position: relative;

            &,
            &:hover {
                cursor: pointer;
                background-color: #ecf2f8;
            }

            &:focus-visible {
                background-color: #ecf2f8;
            }
        }

        &__header-content {
            font-size: 18px;
        }

        &__content {
            margin-bottom: 0;
            padding: 30px;

            @include mq($until: tiny) {
                padding: 15px;
            }
        }
    }

    &.with-icon-in-title {
        .expansion-panel__header {
            padding-top: 13px;
            padding-bottom: 13px;
        }

        .expansion-panel__header-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 30px;

            .trial-cv-btn,
            a {
                display: flex;
                margin-top: 2px;
                margin-left: 20px;

                > svg {
                    font-size: 35px;
                    flex-shrink: 0;
                }
            }
        }
    }
}
