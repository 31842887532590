.talent-details {
    &__overall-info {
        display: grid;
        grid-template-columns: $enterprise-talent-details-avatar-size 1fr 70px;
        grid-column-gap: 20px;
        min-height: 203px;
        padding: 30px;

        @include mq($until: tiny) {
            padding: 13px;
            grid-template-columns: min-content 1fr auto;
            min-height: unset;
        }

        &--preferences {
            display: flex;
        }

        .talent-preferences-info-field {
            display: flex;
            line-height: 21px;
            margin-top: 7px;

            & + & {
                margin-top: 16px;
            }

            > div {
                @include first-child {
                    flex-shrink: 0;
                    width: 92px;
                }

                @include last-child {
                    margin-left: 18px;
                    font-weight: 800;
                    color: $secondary-font-color;
                }
            }
        }
    }

    &__overall-info-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 0;
        overflow-wrap: break-word;

        @include first-child {
            padding-bottom: 20px;
        }

        &:nth-child(2) {
            justify-content: center;
        }

        @include last-child {
            @include not-unique {
                align-items: flex-end;
            }
        }
    }

    &__overall-info-user-image {
        width: $enterprise-talent-details-avatar-size;
        height: $enterprise-talent-details-avatar-size;
        position: relative;
        flex-shrink: 0;

        .user-avatar__image {
            font-size: $enterprise-talent-details-avatar-size;
        }

        @include mq($until: tiny) {
            width: 54px;
            height: 54px;

            .user-avatar__image {
                font-size: 54px;
            }
        }
    }

    &__overall-info-fullname {
        font-size: 24px;
        font-weight: 800;
        line-height: 30px;
        margin: 0;

        @include mq($until: tiny) {
            font-size: 14px;
            line-height: unset;
            margin-top: 15px;
        }
    }

    &__overall-info-location {
        font-size: 18px;
        line-height: 24px;
        margin-top: 15px;

        @include mq($until: tiny) {
            font-size: 12px;
            line-height: unset;
            margin-top: 0;
        }

        + button {
            margin-top: 34px;
            max-width: 132px;
        }
    }

    &__overall-info-match-score {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 17px;
        font-weight: 700;
        margin: auto 0;

        span {
            font-size: 33px;
            line-height: 33px;
            color: $success-color;
        }
    }
}
