.talent-visibility-stats {
    @include friendlySectionAppear(.1s);
    padding-bottom: 22px;

    &__list {
        display: flex;
        margin: 4px 0 12px;

        @include mq($until: small) {
            flex-direction: column;
        }
    }

    &__item {
        height: 106px;
        flex: 1;
        position: relative;

        @include after-first(1) {
            margin-left: 10px;

            @include mq($until: small) {
                margin-left: unset;
                margin-top: 10px;
            }
        }

        &:before {
            @include stretch;
            bottom: auto;
            height: 100%;
            content: "";
            border-radius: 13px;
            opacity: 0;
            box-shadow: 0 5px 16px 0 rgba(0, 0, 0, .08);
            transition: opacity .2s linear;
        }

        &.is-active,
        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &.is-equal {
            .talent-visibility-stats__value {
                color: #2c97fc;
            }

            svg {
                font-size: 10px;
            }
        }

        &.is-decreasing {
            .talent-visibility-stats__value {
                color: $attention-color;
            }

            svg {
                transform: rotate(.5turn);
            }
        }

        &.is-increasing {
            .talent-visibility-stats__value {
                color: $success-color;
            }
        }
    }

    &__item-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        height: 100%;
        width: 100%;
        position: relative;
        background-color: #fff;
        border-radius: 7px;
        z-index: 10;

        .tip {
            align-self: flex-end;
            margin-right: 30px;
            background-color: transparent;
        }
    }

    &__value {
        display: flex;
        align-items: center;
        font-size: 27px;
        font-weight: 900;
        line-height: 34px;

        svg {
            font-size: 16px;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }

    &__label {
        font-weight: 800;
        line-height: 20px;
        margin-top: 10px;
    }
}
