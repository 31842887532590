.full-screen-loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999999;
    background-color: $primary-bg-color;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 15px 100px;
        max-width: 500px;

        &__title,
        &__descriotion {
            animation: fade-in .5s ease-in-out;
        }
    }

    &__spinner-wrapper {
        position: relative;
        width: 150px;
        height: 100px;
    }

    .loader__spinner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        color: $secondary-font-color;
        min-width: min-content;
        min-height: min-content;
    }
}
