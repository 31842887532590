.actions-badge-wrapper {
    position: relative;

    &.badge-is-visible {
        .actions-badge {
            opacity: 1;
        }

        &:hover {
            .actions-badge {
                width: calc(var(--actions-number) * #{$actions-badge-btn-size});
            }
        }
    }
}

.actions-badge {
    --actions-number: 0;
    --immediate-visible-actions-number: 0;

    position: absolute;
    top: 0;
    left: calc(100% - var(--immediate-visible-actions-number) * #{$actions-badge-btn-size});
    width: calc(var(--immediate-visible-actions-number) * #{$actions-badge-btn-size});
    height: $actions-badge-btn-size;
    overflow: hidden;
    border-radius: $actions-badge-btn-size;
    opacity: 0;
    transition: all $main-transition;

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        border-radius: $actions-badge-btn-size;
    }

    &__btn {
        flex-shrink: 0;
        font-size: $actions-badge-btn-size;
        width: 1em;
        height: 1em;
        background-color: $secondary-font-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        order: 2;

        &.is-immediately-visible {
            order: 1;
        }

        &--delete {
            background-color: #1673f3;
        }

        svg {
            color: #fff;
            font-size: 12px;
        }
    }
}
