.marketing-portal {
    background-color: #f4f9ff;

    .monthly-registration-chart {
        max-width: 560px;
        width: 100%;
        height: 330px;
    }

    .account-type-chart {
        width: 100%;
        max-width: 400px;
        height: 310px;
        margin-top: 30px;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
    }

    &__chart-wrapper {
        @include friendlySectionAppear(.2s);

        min-height: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        margin-bottom: 20px;
        margin-right: 8px;
        padding-bottom: 20px;
        border-radius: 8px;

        h2 {
            font-size: 24px;
            font-weight: 700;
            padding: 0 32px;
        }

        &_monthly {
            padding-top: 40px;

            h2 {
                align-self: flex-start;
            }
        }

        .marketing-portal__chart-loader {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .auth-page {
        &__content {
            margin-top: 40px;

            @supports (margin-top: #{"max(10px, min(5vh, 80px))"}) {
                margin-top: #{"max(10px, min(5vh, 80px))"};
            }
        }
    }

    &__site-header {
        .header-user-area {
            position: static;
            height: 100%;
            background-color: transparent;
            display: flex;
            box-shadow: none;

            > .attention-dot {
                position: absolute;
                margin-left: 21px;
                margin-top: 12px;
                align-self: flex-start;
            }
        }

        .hamburger-btn {
            display: flex;
        }

        .user-menu-dropdown {
            top: calc(100% + 8px);
            right: 15px;

            &__options {
                padding-inline: 30px;

                .attention-dot {
                    display: inline-flex;
                    vertical-align: text-top;
                }
            }

            .popover__inner {
                width: auto;
                min-width: 190px;
            }
        }
    }

    &__two-side-layout {
        height: calc(100vh - var(--header-height) - 50px);
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;

        @include mq($until: large) {
            max-width: 100%;
            margin: 0;
        }

        @include mq($until: mobile) {
            display: block;
        }
    }

    &__layout-side {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include last-child {
            .marketing-portal__layout-side-header {
                margin: 40px 0;
            }
        }

        @include mq($from: mobile, $until: large) {
            @include first-child {
                & > .marketing-portal__layout-side-content {
                    padding-left: 20px;
                }
            }

            @include last-child {
                & > .marketing-portal__layout-side-content {
                    padding-right: 20px;
                }
            }
        }
    }

    &__layout-side-header {
        margin: 40px 0;
        font-size: 32px;
        font-weight: 700;

        @include mq($until: large) {
            margin: 40px 20px;
        }
    }

    &__layout-side-content {
        @include customScrollbars($track: transparent);
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        @include mq($until: mobile) {
            padding: 0 20px;
        }
    }

    &__table-placeholder {
        min-height: 776px;
    }

    &__table-wrapper {
        margin: 20px -2px 20px -10px;
    }

    &__table {
        @include friendlySectionAppear(.2s);

        border: none;
        border-collapse: separate;
        border-spacing: 8px;
        table-layout: auto;
        width: 100%;
        margin: 0;

        tr {
            border: none;
            margin: 0;
        }

        th,
        td {
            background-color: #fff;
            border-radius: 8px;
            text-align: left;
        }

        th {
            font-size: 24px;
            font-weight: 700;
            color: #3b89eb;
            padding: 26px 32px 24px;
            text-transform: capitalize;

            &:last-child {
                width: 164px;
                white-space: nowrap;
            }
        }

        td {
            @include friendlySectionAppear(.2s);

            padding: 22px 32px 18px;
            font-size: 16px;
            font-weight: 600;

            &.actions-col {
                white-space: nowrap;
                padding: 8px;
                text-align: center;
            }
        }

        @include mq($until: tablet) {
            border-spacing: 4px;

            th {
                padding: 15px 20px;
                font-size: 20px;

                &:last-child {
                    width: 100px;
                }
            }

            td {
                padding: 10px 20px;
            }
        }

        &-custom-row {
            color: #3b89eb;
        }
    }

    &__empty {
        text-align: center;
        font-size: 22px;
        margin-block-start: 50px;

        td {
            text-align: center;
        }
    }

    .company-actions,
    .request-actions {
        white-space: nowrap;

        button {
            display: inline-flex;
            align-items: center;
            padding: 0;
            height: 32px;
            width: 32px;

            @include after-first(1) {
                margin-inline-start: 5px;
            }

            @include mq($until: tablet) {
                height: 26px;
                width: 26px;
            }
        }
    }

    .company-actions {
        &__merge-icon {
            rotate: 270deg;
        }
    }

    &__requests {
        max-width: 1600px;
        margin: 0 auto;
        padding-inline: 20px;
        padding-block: 0 20px;

        table {
            th {
                &:last-child {
                    width: auto;
                }
            }

            @include mq($until: desktop) {
                border-spacing: 4px;

                th {
                    padding: 15px 20px;
                    font-size: 20px;
                }

                td {
                    padding: 10px 20px;
                }
            }

            @include mq($until: tablet) {
                th {
                    padding: 10px;
                    font-size: 18px;
                }

                td {
                    padding: 10px 12px;
                }
            }
        }

        &__steps {
            display: flex;
            align-items: center;
            grid-gap: 20px;

            &__item {
                padding: 0 0 4px 0;
                border-bottom: 1px solid transparent;

                &--active {
                    color: $secondary-font-color;
                    font-weight: 700;
                    border-bottom: 1px solid $secondary-font-color;
                }
            }
        }
    }

    .company-profiles {
        &__logo-cell {
            max-width: 50px;
            max-height: 50px;

            img {
                width: 100%;
            }
        }

        &__link-cell {
            transition: opacity $main-transition;
            opacity: 1;

            &:hover {
                opacity: .6;
            }
        }

        &__label {
            color: $secondary-font-color;
        }

        &__search {
            position: relative;
            width: 100%;
            max-width: 292px;
            margin: 0 10px;

            @include mq($until: desktop) {
                margin: 23px auto 28px;
                max-width: unset;
            }

            @include mq($until: mobile) {
                margin-top: 30px;
            }

            .floating-label {
                margin-top: 0;

                input {
                    padding-right: 30px;
                }
            }

            button {
                @include absoluteCenter;
                left: auto;
                bottom: 9px;
                position: absolute;
                height: 20px;
                width: 20px;
                padding: 0;
                font-size: 20px;
                color: $secondary-font-color;
                box-shadow: none;

                &:disabled {
                    color: rgba(0, 0, 0, .35);
                }

                &,
                &:disabled,
                &:hover,
                &:active {
                    background-color: transparent;
                }
            }
        }
    }
}
