.file-dropzone-upload {
    text-align: center;
    padding: 36px 18px 36px;
    margin: 4px;
    border-radius: 7px;
    color: rgba($secondary-font-color, .4);
    position: relative;
    transition: background-color $main-transition;

    &--bordered {
        border: 2px dashed $form-active-border-color;
        background-color: $form-action-area-bg;
        color: $secondary-font-color;
    }

    &--drag-active {
        background-color: $form-action-area-bg;
    }

    &__image {
        position: relative;
        height: 122px;
        margin-top: 20px;
        margin-bottom: 10px;

        .user-avatar__image {
            font-size: $preview-upload-user-avatar-size;
            margin: 0 auto;
        }

        svg {
            height: 100%;
            width: auto;
        }

        &--company-logo {
            height: $enterprise-company-logo-upload-height;
            margin-top: 10px;
            margin-bottom: 0;

            .user-avatar__image {
                font-size: $enterprise-company-logo-upload-height;
                border-radius: initial;

                > img {
                    border-radius: initial;
                    width: auto;
                    object-fit: initial;
                }
            }

            + .file-dropzone-upload__limitation-info {
                margin-top: 10px;
            }
        }

        &--only-image {
            height: $enterprise-company-logo-upload-height;

            .user-avatar__image {
                font-size: $enterprise-company-logo-upload-height;
            }

            + .file-dropzone-upload__actions {
                display: none;
            }
        }

        .talent-edit-cv__link & {
            padding: 24px 14px;
            border-radius: 7px;
            box-shadow: 0 3px 6px rgba(#000, .16);
        }
    }

    &__actions {
        position: relative;
    }

    &__btn {
        margin-top: 16px;
        padding-left: 38px;
        padding-right: 38px;
    }

    &__error {
        text-align: center;
        width: 100%;
    }

    &__limitation-info {
        font-size: 9px;
    }
}
