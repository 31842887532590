.edit-request-modal {
    border-radius: 7px;

    @include mq($until: mobile) {
        border-radius: 0;
    }

    .modal__header {
        align-items: center;

        @include mq($until: mobile) {
            flex-direction: row;
            justify-content: space-between;
            padding: 19px;
        }
    }

    .modal__close {
        @include mq($until: mobile) {
            position: unset;
        }
    }

    &__actions {
        margin-top: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;

        button {
            width: 184px;
        }

        @include mq($until: mobile) {
            margin-top: 164px;
        }
    }

    .modal__content {
        @include mq($until: mobile) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
        }
    }

    &__body {
        padding-bottom: 32px;

        @include mq($until: mobile) {
            max-height: unset;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .floating-label,
            .custom-select {
                max-width: 300px;

                @include mq($until: mobile) {
                    max-width: unset;
                }
            }

            @include mq($until: mobile) {
                padding: 0 19px;
                align-items: stretch;
            }
        }
    }
}
