@keyframes spinner-loader-animation {
    0% {
        box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
    }

    20% {
        box-shadow: 40px -1px 0 -2px, 0 0 0 -2px, 40px -1px 0 -5px, 0 0 0 -5px;
    }

    40% {
        box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 40px -1px 0 -5px, -40px -1px 0 -5px;
    }

    60% {
        box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px, -40px -1px 0 -5px;
    }

    80%,
    95% {
        box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px, -23px -29px 0 -5px;
    }

    100% {
        box-shadow: 40px -1px 0 -2px rgba($secondary-bg-color, 0), -40px -1px 0 -2px rgba($secondary-bg-color, 0),
            23px -29px 0 -5px rgba($secondary-bg-color, 0), -23px -29px 0 -5px rgba($secondary-bg-color, 0);
    }
}

@keyframes spinner-loader-animation-s {
    0% {
        box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
    }

    20% {
        box-shadow: 40px 2px 0 -2px, 0 0 0 -2px, 40px 2px 0 -5px, 0 0 0 -5px;
    }

    40% {
        box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 40px 2px 0 -5px, -40px 2px 0 -5px;
    }

    60% {
        box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px, -40px 2px 0 -5px;
    }

    80%,
    95% {
        box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px, -23px -23px 0 -5px;
    }

    100% {
        box-shadow: 40px 2px 0 -2px rgba($secondary-bg-color, 0), -40px 2px 0 -2px rgba($secondary-bg-color, 0),
            23px -23px 0 -5px rgba($secondary-bg-color, 0), -23px -23px 0 -5px rgba($secondary-bg-color, 0);
    }
}

@keyframes fade-in-blocking {
    0% {
        opacity: 0;
    }

    100% {
        pointer-events: auto;
        opacity: 1;
    }
}

.loader {
    &__container {
        position: relative;
        min-height: 100vh;
    }

    &__backdrop {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $loader-backdrop-bg;
        pointer-events: none;
        opacity: 0;
        animation: fade-in-blocking .3s linear forwards .5s;

        &--solid {
            animation: fade-in-blocking .2s linear forwards .2s;
            background-color: $primary-bg-color;

            .loader__spinner {
                color: $secondary-font-color;
            }
        }
    }

    &__spinner {
        position: fixed;
        top: calc(100vh / 2 - 12px);
        left: 0;
        right: 0;
        margin: auto;

        width: 48px;
        height: 24px;
        color: $secondary-bg-color;
        background: currentColor;
        border-radius: 50% 50% 0 0;
        margin-top: 36px;
        animation: spinner-loader-animation 2s linear infinite 1s, fade-in .3s linear forwards 1s;
        opacity: 0;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: currentColor;
            top: -34px;
            animation: spinner-loader-animation-s 2s linear infinite 1s;
        }
    }
}
