//Colors
$primary-bg-color: #f8f8f8;
$secondary-bg-color: #fff;
$primary-font-color: #010101;
$secondary-font-color: #1688f3;
$unsuccess-color: #f00;
$unsuccess-light-color: #f55757;
$attention-color: #eb307e;
$success-color: #51cb20;
$aux-bg-color: #ecf2f8;
$minor-font-color: #6a6a6a;

//Layout
$footer-height: 205px;
$header-height: 60px;
$container-width: 1090px;
$mobile-user-area-height: 60px;

//Transition
$main-cubic-bezier-fn: cubic-bezier(0, 0, .3, 1);
$main-transition: .3s $main-cubic-bezier-fn;

//Buttons
$button-primary-color: #fff;
$button-primary-bg: #1688f3;
$button-primary-bg-a: #2c97fc;
$button-text-disabled-color: lighten($secondary-font-color, 18%);
$button-success-bg: $success-color;
$button-success-bg-a: lighten($success-color, 4%);
$button-disabled-bg: #d8e0ef;

//Forms
$form-border-color: #d8e0ef;
$form-active-border-color: #1688f3;
$form-error-border-color: #f55757;
$form-value-tag-bg: #ecf2f8;
$form-action-area-bg: #e7f2fd;

//User avatar
$sidebar-user-avatar-size: 168px;
$header-user-avatar-size: 40px;
$account-settings-avatar-size: 100px;
$preview-upload-user-avatar-size: 122px;
$talent-summary-card-avatar-size: 61px;
$enterprise-talent-details-avatar-size: 122px;
$enterprise-talent-similar-avatar-size: 61px;
$enterprise-company-logo-upload-height: 85px;
$compare-popup-avatar-size: 40px;
$compare-modal-avatar-size: 189px;
$chat-avatar-size: 40px;

//Register options
$register-type-option-size: 260px;

//Modal
$modal-bg: #fff;
$modal-backdrop-bg: rgba(#1688f3, .5);
$modal-width: 590px;

//Drawer
$drawer-bg: #fff;
$drawer-width: 351px;

//Loader
$loader-backdrop-bg: rgba(#1688f3, .1);

//Sidebar
$profile-sidebar-width: 322px;
$wizard-sidebar-width: 352px;
$sidebar-nav-item-bg: #ecf1fa;
$wizard-sidebar-active-step-bg: #ecf1fa;

//Tip
$tip-size: 22px;
$tip-bg: #ecf1fa;

//Actions badge
$actions-badge-btn-size: 27px;

//Tooltip
$tooltip-max-width: 220px;
$tooltip-min-width: 160px;
$tooltip-bg: #ecf1fa;

//Headhunter card
$hh-pool-card-min-width: 385px;
$hh-pool-card-height: 170px;

//Range input
$range-input-track-height: 6px;
$range-input-handle-height: 18px;
$range-input-handle-width: 4px;
$range-input-rail-color: #d8e0ef;
$range-input-track-color: $success-color;

//Attention dot
$attention-dot-size: 8px;

//Fonts
$main-font: Mulish, "Segoe UI", Helvetica, Arial, sans-serif;
$base-font-size: 16px;

//Breakpoints
$mq-breakpoints: (
    tiny: 455px,
    small: 561px,
    mobile: 768px,
    tablet: 901px,
    desktop: 1101px,
    large: 1301px,
    wide: 1367px
);

// Status dot colors
$live-dot-color: #51cb20;
$declined-dot-color: #e0ddde;
$expired-dot-color: #eb307e;
$pending-dot-color: #ebc330;
