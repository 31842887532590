* {
    box-sizing: border-box;
}

html {
    &.hide-scroll {
        @include mq($until: mobile) {
            -webkit-overflow-scrolling: auto;
        }
    }
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    @include customScrollbars;
    font-family: $main-font;
    font-size: $base-font-size;
    color: $primary-font-color;
    background-color: $primary-bg-color;
    font-weight: 400;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @at-root .hide-scroll & {
        @include mq($until: mobile) {
            overflow: hidden;
            touch-action: none;
            -webkit-overflow-scrolling: auto;
        }
    }
}

a {
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
}

a:active,
a:hover {
    outline: 0;
}

a:hover,
a:focus {
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: 1.2em;
}

img {
    image-rendering: -webkit-optimize-contrast; /* stylelint-disable-line value-no-vendor-prefix */
    image-rendering: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $secondary-font-color;
}

