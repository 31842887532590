.paginator {
    @include friendlySectionAppear(.1s);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: small) {
        flex-direction: column-reverse;
    }

    &__limit {
        height: 40px;
        display: flex;
        align-items: center;

        select,
        label {
            flex-shrink: 0;
        }

        label {
            margin-left: 18px;
            font-size: 14px;
            line-height: 18px;
        }

        select {
            border: none;
            cursor: pointer;
            border-radius: 7px;
            height: 100%;
            width: 71px;
        }
    }

    &__pages {
        height: 40px;

        @include mq($until: small) {
            margin-bottom: 20px;
        }
    }

    &__pages-inner {
        padding: 0;
        margin: 0;
        display: flex;
        font-size: 14px;
        line-height: 18px;
        height: 100%;

        li {
            display: inline-flex;
            align-items: center;
            height: 100%;

            &:not(.paginator__next-btn, .paginator__prev-btn) {
                background-color: #fff;
                padding: 0 4px;
            }

            &:nth-child(2) {
                padding-left: 15px;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
            }

            @include from-end(2) {
                padding-right: 15px;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
            }
        }
    }

    &__next-btn {
        margin-left: 24px;
    }

    &__prev-btn {
        margin-right: 24px;
    }

    &__page-btn {
        &.active {
            color: $secondary-font-color;
        }
    }
}
