.timer-expired-modal {
    border-radius: 7px;

    @include mq($until: mobile) {
        border-radius: 0;
    }

    .modal__header {
        align-items: center;
        color: $attention-color;
        position: initial;
    }

    .modal__content {
        @include mq($until: mobile) {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
        }
    }

    &__actions {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 184px;
        }
    }

    &__body {
        padding-bottom: 50px;

        @include mq($until: mobile) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
        }
    }

    &__alert-text {
        text-align: center;
        font-weight: 800;
        margin: 0 40px;
    }
}
