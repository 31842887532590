.talent-details-page {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 30px;

    @include mq($until: desktop) {
        height: unset;
        flex-direction: column;
        padding: 0 19px 40px;
        margin-bottom: $mobile-user-area-height;
        align-items: center;
    }

    .experience-wizard-item__experience-item__logo {
        @include mq($until: mobile) {
            margin-bottom: 15px;
        }
    }
}

.talent-details {
    &__main {
        width: 100%;
        max-width: 720px;

        @include mq($until: desktop) {
            margin-top: 30px;
        }
    }

    &__similar {
        border-radius: 7px;
        width: 352px;
        flex-shrink: 0;

        + .talent-details__main {
            margin-left: 20px;
        }
    }

    &__section {
        @include friendlySectionAppear;
        border-radius: 8px / 5px;
        background-color: $secondary-bg-color;

        & + & {
            margin-top: 20px;
        }

        &__content {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            &__progress-bar {
                flex-basis: 130px;
            }

            @include mq($until: mobile) {
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr;
            }
        }
    }

    &__personal-skills {
        .expansion-panel__content {
            padding-bottom: 15px;
        }
    }

    &__comparison-chart {
        padding: 10px 30px;
    }

    &__education {
        .wizard-items-list__item {
            @include last-child {
                margin-bottom: 0;
            }

            margin-bottom: 35px;
        }
    }

    &__cv-summary {
        padding: 20px 30px;

        @include mq($until: tiny) {
            padding: 13px;
        }
    }
}
