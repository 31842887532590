.user-menu-dropdown {
    top: calc(100% + 20px);
    right: 0;

    .popover__inner {
        padding: 0;
        width: 281px;
    }

    &__head {
        padding: 11px 10px;
        display: flex;
    }

    &__info {
        overflow: hidden;
        margin-left: 10px;
        font-size: 14px;
        line-height: 18px;
    }

    &__full-name {
        @include ellipsis;
        font-weight: 800;
    }

    &__email {
        @include ellipsis;
        margin-top: 1px;
        color: #888;
    }

    &__options {
        border-top: 1px solid #ecf1fa;
        padding: 16px 10px 19px 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__option {
        font-size: 16px;
        line-height: 20px;

        @include after-first(1) {
            margin-top: 10px;
        }

        &.with-attention {
            color: $attention-color;
        }
    }

    .header-user-avatar__image {
        cursor: default;
    }

    &__credits-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > p {
            margin-bottom: 5px;
            font-weight: 600;
        }
    }

    &__trial {
        font-size: 12px;
        color: $secondary-font-color;
    }

    &__progress-wrapper {
        width: 100%;
    }

    &__progress {
        width: 100%;
        height: 9px;

        appearance: none;
        border: none;
        overflow: hidden;

        &::-webkit-progress-bar {
            background-color: $form-value-tag-bg;
        }

        &::-webkit-progress-value {
            background-color: $secondary-font-color;
            background-size: 4px 20px;
        }

        &::-moz-progress-bar {
            background-color: $secondary-font-color;
            background-size: 4px 20px;
        }
    }
}
