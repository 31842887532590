.enterprise-details {
    &__company-logo-section {
        margin-top: 45px;
        margin-bottom: -30px;

        @include mq($until: tiny){
            text-align: center;
        }
    }

    .file-dropzone-upload {
        padding-top: 20px;
    }
}
