.hh-pool-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    position: relative;
    background-color: #fff;
    border-radius: 7px;

    &:after {
        @include stretch;
        content: "";
        border-radius: 7px;
        pointer-events: none;
        background-color: rgba(255, 255, 255, .8);
        opacity: 0;
        transition: opacity $main-transition;
    }

    @include mq($from: small, $until: mobile) {
        width: 412px;
    }

    @include mq($until: small) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    &.is-processing {
        cursor: default;
        user-select: none;

        &:after {
            pointer-events: auto;
            opacity: 1;
        }
    }

    &.is-hunting {
        overflow: hidden;

        .progress-bar--indeterminate {
            display: flex;
        }
    }

    &__name {
        padding-right: 65px;
        font-size: 20px;
        font-weight: 800;
        line-height: 32px;
        color: $secondary-font-color;
    }

    &__actions {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    &__stats {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__stat {
        display: flex;
        align-items: baseline;
        line-height: 21px;
        white-space: nowrap;

        @include after-first(1) {
            margin-left: 5px;
        }

        @include mq($until: small) {
            flex-direction: column;
        }

        span {
            font-size: 32px;
            font-weight: 900;
            line-height: 44px;
            margin-right: 5px;
            color: $success-color;
        }
    }
}
