.atlaskit-tooltip {
    &__warning,
    &__content {
        box-sizing: border-box;
        top: 0;
        left: 0;
        max-width: $tooltip-max-width;
        word-wrap: break-word;
        overflow-wrap: break-word;
        background-color: $tooltip-bg;
        border-radius: 7px;
        font-size: 12px;
        padding: 12px;
    }

    &__warning {
        color: $primary-font-color;
        background-color: $pending-dot-color;
    }
}
