.auth-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.login-page {
        .auth-page__title {
            text-transform: uppercase;
        }
    }

    &__logo {
        @include friendlySectionAppear;
        flex-shrink: 0;
        display: flex;
        margin-top: 60px;

        @supports (margin-top: #{"max(30px, min(6vh, 60px))"}) {
            margin-top: #{"max(30px, min(6vh, 60px))"};
        }

        @include mq($until: mobile) {
            margin-top: 21px;
            align-self: flex-start;
            margin-left: 20px;
        }

        svg {
            max-height: 98px;
            font-size: 12vh;

            @include mq($until: mobile) {
                font-size: 28px;
            }
        }
    }

    &__content {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 40px;
        padding: 0 20px 50px;

        @supports (margin-top: #{"max(10px, min(5vh, 80px))"}) {
            margin-top: #{"max(10px, min(5vh, 80px))"};
        }

        @include mq($until: mobile) {
            margin-top: 0;
        }
    }

    &__title {
        @include friendlySectionAppear(.1s);
        font-size: 44px;
        margin: 0;
        font-weight: 900;

        @include mq($until: mobile) {
            font-size: 17px;
            margin-top: 0;
            font-weight: 800;
            line-height: 21px;
        }
    }

    &__link {
        line-height: 24px;
        color: $secondary-font-color;
        margin-top: 23px;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .1s;
        will-change: opacity;
    }

    &__social-signin-title {
        margin: 25px 0 0;
        font-size: 16px;
        color: $primary-font-color;
        text-align: center;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .1s;
    }

    &__social-signin {
        margin-top: 16px;
        max-width: 352px;
        width: 100%;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .17s;

        & + & {
            animation-delay: .24s;
        }
    }

    &__login-link {
        line-height: 24px;
        margin-top: 23px;
        text-align: center;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .5s;
        will-change: opacity;

        a {
            color: $secondary-font-color;
        }
    }

    form {
        padding: 0 30px 33px;
        margin-top: 35px;
        border-radius: 7px;
        width: 100%;
        max-width: 353px;
        background-color: $secondary-bg-color;
        display: flex;
        flex-direction: column;
        opacity: 0;
        animation: slide-from-bottom $main-transition forwards .1s;
        will-change: opacity;

        @include mq($until: mobile) {
            margin-top: 26px;
        }

        button {
            width: 184px;
            align-self: center;
            margin-top: 31px;
        }
    }

    .auth-fail-screen {
        opacity: 0;
        animation: fade-in $main-transition forwards;

        .result-screen__content {
            line-height: 24px;
        }

        .result-screen__body {
            animation-delay: .4s;
        }

        .result-screen__head,
        .result-screen__footer {
            svg {
                animation-delay: .3s;
            }
        }
    }

    &__form--fallback {
        padding: 0 30px 33px;
        margin-top: 35px;
        height: 234px;

        + .auth-page__link {
            display: none;
        }
    }
}
