.home-page {
    &__btn {
        --button-bg: #{$button-primary-bg};
        --button-bg-a: #{$button-primary-bg-a};

        text-transform: none;
        height: 56px;
        border-radius: 56px;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        background-color: transparent;

        & + & {
            margin-left: 18.5px;
        }

        &:before {
            @include stretch;
            content: "";
            border-radius: 56px;
            background-color: var(--button-bg);
            transition: all $main-transition;
        }

        &:hover,
        &:active {
            background-color: transparent;

            &:before {
                background-color: var(--button-bg-a);
            }
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 11px;
            right: 11px;
            height: 47px;
            background: linear-gradient(91.1deg, rgba(105, 101, 232, .62) -3.21%, rgba(174, 107, 228, .69) 42.84%, rgba(255, 114, 224, .39) 96.13%);
            opacity: .7;
            z-index: -1;
            filter: blur(13px);
            border-radius: 10px;
            pointer-events: none;
        }
    }

    &__btn-content {
        @include stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 56px;
    }

    &__primary-btn {
        width: 162px;
    }
}
