.photo-step {
    .wizard-step__inner {
        min-height: 441px;
        padding-bottom: 20px;
    }

    .wizard-step__title {
        margin-bottom: 30px;
    }
}
