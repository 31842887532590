.sidebar {
    &__inner {
        position: sticky;
        background-color: $secondary-bg-color;
        max-height: calc(100vh - var(--header-height) + 40px);
        top: calc(var(--header-height) + 40px);
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 0 30px;
        min-height: 110px;
    }

    &__title {
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
    }
}
