.plus-btn {
    font-size: 61px;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    position: relative;

    &:before,
    &:after {
        @include absoluteCenter;
        content: "";
        background-color: #fff;
    }

    &:before {
        height: 15px;
        width: 3px;
    }

    &:after {
        width: 15px;
        height: 3px;
    }
}
