@keyframes percentile-reveal {
    from {
        transform: translateX(0%);
    }
}

.talent-details {
    &__percentile {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        min-height: 263px;

        svg {
            @include friendlySectionAppear;
            font-size: 33px;
            color: $secondary-font-color;

            .percentile-icon-progress {
                animation: percentile-reveal 1s $main-cubic-bezier-fn forwards;
            }
        }
    }

    &__percentile-title {
        @include friendlySectionAppear;
        margin: 0 0 19px;
        font-size: 24px;
        font-weight: 800;
        line-height: 30px;
    }

    &__percentile-info {
        @include friendlySectionAppear(.1s);
        margin-top: 30px;
        line-height: 21px;
    }
}
