.account-settings {
    @include friendlySectionAppear;
    height: 100%;
    padding: 0 30px;

    @include mq($until: mobile) {
        margin-bottom: $mobile-user-area-height;
        padding: 0;
        background-color: $secondary-bg-color;
    }

    &__wrapper {
        position: relative;
        width: 100%;
    }

    &.is-hidden {
        display: none;
    }

    &__content {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: $wizard-sidebar-width 1fr;
        padding: 40px 0 35px;

        @include mq($until: mobile) {
            padding: 0;
            overflow-x: auto;
        }

        @include mq($until: tablet) {
            display: flex;
            flex-direction: column;
        }

        &_scrollable {
            @include mq($until: mobile) {
                overflow-x: auto;
            }
        }
    }

    &__inner {
        width: 100%;
        overflow-x: auto;

        @include mq($until: mobile) {
            text-align: center;
        }

        &_scrollable {
            overflow-x: auto;
        }
    }

    &__main {
        background-color: $secondary-bg-color;
        border-radius: 7px;
        padding: 30px 30px 60px;
        min-height: 410px;

        @include mq($until: mobile) {
            padding-bottom: 30px;
        }

        &_transparent {
            padding: 0 0 60px;
            background-color: transparent;

            @include mq($until: mobile) {
                padding: 30px;
            }
        }
    }

    &__body {
        @include mq($until: mobile) {
            min-height: 465px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        margin: 0 0 43px;
        font-size: 24px;
        font-weight: 900;
        line-height: 30px;

        @include mq($until: mobile) {
            text-align: start;
        }
    }

    &__back-btn {
        display: inline-flex;
        align-items: center;
        line-height: 20px;
        margin-top: 50px;

        @include mq($until: mobile) {
            margin: 0 0 20px;
        }

        svg {
            transform: translateY(1px);
            font-size: 12px;
            margin-right: 18px;
        }

        &_additional {
            @include mq($until: mobile) {
                margin: 40px 0 40px;
                margin-left: 50%;
                transform: translateX(-50%);
            }

            @include mq($from: mobile, $until: tablet) {
                margin: 60px 0 20px 30px;
            }
        }
    }

    &__save-btn {
        width: 184px;

        @include mq($until: mobile) {
            align-self: center;
        }
    }
}
