.header-user-area {
    min-width: 0;
    display: flex;
    align-items: center;
    height: 100%;

    @include mq($until: mobile) {
        height: $mobile-user-area-height;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $secondary-bg-color;
        display: grid;
        grid-template-columns: 2fr 1fr;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, .078);

        &__actions {
            justify-content: space-around;
        }

        .header-user-avatar {
            display: flex;
            justify-content: center;
        }

    }

    &__actions {
        display: flex;
        margin: 0 16px 0 0;

        > * {
            @include after-first(1) {
                margin-left: 20px;
            }
        }
    }
}

.header-auth {
    @include friendlySectionAppear(.1s);
    display: flex;
    align-items: center;

    @include mq($until: mobile) {
        flex-direction: column;
        align-items: flex-end;

        > a {
            &.site-header__link {
                animation-delay: .17s;
            }
        }
    }

    &__btn {
        background-color: transparent;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 600;
        color: $primary-font-color;
        box-shadow: none;
        width: 142px;
        margin-left: 30px;
        height: 46px;
        border-radius: 46px;
        border: solid 1px #e8e6ec;

        &:hover {
            color: #fff;
        }

        @include mq($until: mobile) {
            font-size: 18px;
            line-height: 20px;
            margin-left: 0;
            text-transform: none;
            height: auto;
            width: auto;
            padding: 0;
            flex-shrink: 0;
            border: none;
            border-radius: 0;
            margin-bottom: 40px;
            transition: all .15s linear;

            &:hover,
            &.is-active {
                background-color: transparent;
                color: $primary-font-color;
            }
        }
    }
}

.header-user-avatar {
    @include friendlySectionAppear(.1s);
    position: relative;

    &__dropdown-trigger {
        display: flex;
        align-items: center;
        grid-gap: 3px;
        padding: 0;
        margin: 0;
    }

    &__chevron-icon {
        rotate: -90deg;
        margin-top: 2px;

        &.is-rotated {
            rotate: 90deg;
        }

        g {
            fill: $button-primary-bg;
        }

        @include mq($until: mobile) {
            display: none;
        }
    }

    .user-avatar__image {
        font-size: $header-user-avatar-size;
    }

    > .user-avatar__image {
        cursor: pointer;
    }
}

.header-enterprise-nav,
.header-talent-nav {
    display: flex;
    align-items: stretch;
    margin-right: 21px;
    height: 100%;

    @include mq($until: mobile) {
        margin: 0;
        height: 50px;
        justify-content: flex-end;
        margin-top: 15px;
        align-self: flex-end;
        margin-left: auto;
    }

    .header-enterprise-nav__link,
    .header-talent-nav__link {
        padding: 0 20px;
        padding-inline: 20px;
        margin: 0 5px;
        margin-inline: 5px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        text-transform: capitalize;

        @include mq($until: mobile) {
            padding: 0 10px;
        }

        @include mq($until: desktop) {
            padding-inline: 5px;
        }

        &:after {
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            content: "";
            display: block;
            position: absolute;
            background-color: $button-primary-bg;
            transition: opacity $main-transition;
            opacity: 0;

        }

        &.active {
            &:after {
                opacity: 1;
            }
        }
    }
}
