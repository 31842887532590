.custom-checkbox {
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    flex-direction: column;

    input {
        display: none;

        &:checked {
            + label {
                .custom-checkbox__checkbox {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__container,
    label {
        user-select: none;
        cursor: pointer;
        color: $primary-font-color;
        display: flex;
        align-items: flex-start;
    }

    &__checkbox {
        width: 22px;
        height: 22px;
        border: 1px solid $form-border-color;
        position: relative;
        border-radius: 2px;
        flex-shrink: 0;

        &:after {
            @include absoluteCenter;
            content: "";
            border-radius: 2px;
            width: 14px;
            height: 14px;
            background-color: $form-active-border-color;
            opacity: 0;
            transition: opacity $main-transition;
        }
    }

    &__label {
        line-height: 20px;
        margin-left: 10px;
        transition: color $main-transition;
    }

    &__error {
        color: $unsuccess-color;
        font-size: 9px;
        line-height: 11px;
        margin-top: 2px;
        animation: fade-in $main-transition;
    }
}
