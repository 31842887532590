.account-edit-personal-info {
    &__photo {
        width: $account-settings-avatar-size;

        &.is-processing {
            .actions-badge__btn {
                cursor: default;
                pointer-events: none;
            }
        }

        .user-avatar__image {
            font-size: $account-settings-avatar-size;
        }
    }

    &__name {
        @include mq($until: mobile) {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .floating-label {
                text-align: start;
            }
        }

        button {
            margin-top: 144px;
        }
    }
}
