.talent-edit-cv {
    .actions-badge {
        margin: -8px 0 0 11px;
    }

    &__card {
        width: 74px;
        height: 92px;
        border-radius: 7px;
        box-shadow: 0 3px 6px rgba(#000, .16);

        &.is-processing {
            .actions-badge__btn {
                cursor: default;
                pointer-events: none;
            }
        }
    }

    &__card-icon {
        margin: 25px 16px 23px 13px;
        font-size: 45px;
    }

    &__origin-name {
        font-size: 14px;
        color: $secondary-font-color;
        font-weight: 400;
    }

    &__link {
        display: inline-block;
    }

    .talent-edit-cv_summary {
        padding: 10px 0 0;
    }
}
