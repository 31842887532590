.search-talent-form {
    position: relative;
    width: 100%;
    max-width: 292px;
    margin: 0 10px;

    @include mq($until: desktop) {
        margin: 23px auto 28px;
        max-width: unset;
    }

    @include mq($until: mobile) {
        margin-top: 30px;
    }

    .floating-label {
        margin-top: 0;

        input {
            padding-right: 30px;
        }
    }

    button {
        @include absoluteCenter;
        left: auto;
        bottom: 9px;
        position: absolute;
        height: 20px;
        width: 20px;
        padding: 0;
        font-size: 20px;
        color: $secondary-font-color;
        box-shadow: none;

        &:disabled {
            color: rgba(0, 0, 0, .35);
        }

        &,
        &:disabled,
        &:hover,
        &:active {
            background-color: transparent;
        }
    }
}
