.search-filters {
    @include friendlySectionAppear;
    position: sticky;
    top: var(--header-height);
    background-color: $primary-bg-color;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;

    @include mq($until: desktop) {
        margin-block-end: 30px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 10px;
        top: 10px;
        pointer-events: none;
        width: 17px;
        z-index: 10;
        opacity: 1;
    }

    &:after {
        right: 0;
        background-image: linear-gradient(to left, $primary-bg-color, rgba($primary-bg-color, 0));
    }

    &:before {
        left: 0;
        background-image: linear-gradient(to right, $primary-bg-color, rgba($primary-bg-color, 0));
    }

    &__inner {
        display: flex;
        padding-block: 10px;
        align-items: center;
        white-space: nowrap;
        max-width: 100%;
        overflow-x: auto;

        &:before,
        &:after {
            content: "";
            width: 12px;
            pointer-events: none;
            background-color: transparent;
            display: block;
            flex-shrink: 0;
            height: 1px;
        }

        @include onMouseDevices {
            @include customScrollbars;
        }
    }

    + .talent-cards-result {
        margin-block-start: 40px;
    }

    .search-filter-btn {
        + .search-filter-btn {
            margin-inline-start: 5px;
        }
    }
}

.search-filter-btn {
    @include friendlySectionAppear;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    flex-shrink: 0;
    user-select: none;
    white-space: nowrap;

    input {
        display: none;

        &:checked {
            + label {
                background-color: darken($button-primary-bg, 20%);
            }
        }

        &:disabled {
            + label {
                pointer-events: none;
                cursor: default;
                background-color: $button-disabled-bg;
            }
        }
    }

    label {
        text-transform: capitalize;
        padding: 0 14px;
        text-align: center;
        height: 44px;
        cursor: pointer;
        color: $button-primary-color;
        background-color: $button-primary-bg;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all $main-transition;

        @include mq($until: desktop) {
            font-size: 14px;
            height: 36px;
        }

        @include mq($until: mobile) {
            height: 32px;
            font-size: 12px;
        }

        @include onMouseDevices {
            &:hover {
                background-color: $button-primary-bg-a;
            }
        }
    }
}
